const LargeQuotes = () => (
  <svg
    width="199"
    height="188"
    viewBox="0 0 199 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.3266 128.994C67.5235 128.304 66.6355 127.823 66.1646 128.366C52.4585 144.169 43.3218 157.649 38.5099 166.451C37.8012 167.747 36.1903 168.273 34.8668 167.607L1.47221 150.797C0.151816 150.133 -0.391254 148.549 0.303581 147.249C17.0235 115.964 68.6108 50.4283 159.024 0.336962C160.26 -0.347274 161.828 0.0408339 162.588 1.22659L182.868 32.8471C183.629 34.0326 183.333 35.5997 182.21 36.4544C172.829 43.5987 160.319 55.0332 148.015 69.6729C147.512 70.2711 148.282 71.0901 148.925 70.6435C158.353 64.0901 168.471 58.1408 179.237 53.1744C180.58 52.5545 182.166 53.1611 182.769 54.5072L198.021 88.5403C198.625 89.8871 198.015 91.4629 196.674 92.0892C173.196 103.052 152.474 120.714 135.803 139.256C119.512 157.374 107.952 175.369 102.117 186.532C101.547 187.623 100.319 188.204 99.1127 187.934L62.6979 179.796C61.4957 179.527 60.6297 178.478 60.6178 177.252C60.4544 160.39 62.9805 144.222 67.3266 128.994Z"
      fill="#C6B5F4"
      fillOpacity="0.5"
    />
  </svg>
);

export default LargeQuotes;
