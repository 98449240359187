const Youtube = () => (
  <svg
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17L16 16.9557C18.8132 16.8672 20.6007 16.7196 21.3626 16.513C21.8901 16.3655 22.3443 16.0998 22.7253 15.7161C23.1062 15.3325 23.37 14.8898 23.5165 14.388C23.7216 13.5911 23.8681 12.3663 23.956 10.7135L24 8.5L23.956 6.2865C23.8681 4.66319 23.7216 3.45312 23.5165 2.65625C23.37 2.125 23.1062 1.66753 22.7253 1.28385C22.3443 0.90017 21.8901 0.63455 21.3626 0.48698C20.6007 0.28038 18.8132 0.13281 16 0.04427L12 0L8 0.04427C5.18681 0.13281 3.39927 0.28038 2.63736 0.48698C2.10989 0.63455 1.65568 0.90017 1.27473 1.28385C0.893773 1.66753 0.630036 2.125 0.483516 2.65625C0.278388 3.45312 0.131868 4.66319 0.043956 6.2865C0.0146518 7.1128 0 7.8507 0 8.5L0.043956 10.7135C0.131868 12.3663 0.278388 13.5911 0.483516 14.388C0.630036 14.8898 0.893773 15.3325 1.27473 15.7161C1.65568 16.0998 2.10989 16.3655 2.63736 16.513C3.39927 16.7196 5.18681 16.8672 8 16.9557L12 17ZM9.6 12.1429V4.85714L15.6 8.4777L9.6 12.1429Z"
      fill="#0B081C"
    />
  </svg>
);

export default Youtube;
