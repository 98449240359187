import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import { Toaster } from 'react-hot-toast';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppThemeProvider } from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <AppThemeProvider>
      <App />
    </AppThemeProvider>
    <Toaster
      position="bottom-center"
      toastOptions={{
        className: '',
        style: {
          border: '1px solid #6936F5',
          padding: '8px 16px',
          color: 'white',
          fontSize: "14px",
          backgroundColor: '#6936F5'
        },
      }}
    />
  </StrictMode>
);

reportWebVitals();
