export const appRadius = {
  xMin: '4px',
  min: '6px',
  xs: '8px',
  sm: '12px',
  md: '24px',
  lg: '32px',
  xl: '36px',
  max: '100%',
} as const;
