import styled, { css } from 'styled-components';
import TestimonyCard from './item';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { testimonies } from './data';
import { Heading } from '../ui';
import TestimonyQuotes from './quotes';
import { AppIcons } from '../../assets';
import './carousel.css';

const TestimonySection = () => {
  return (
    <Container>
      <TitleContainer>
        <Heading size="sm" weight="medium">
          See why our customers love us
        </Heading>
      </TitleContainer>
      <CarouselContainer>
        <CarouselProvider
          naturalSlideWidth={852}
          naturalSlideHeight={254}
          totalSlides={testimonies.length}
          infinite
          className="carousel-provider"
        >
          <div>
            <Slider className="carousel-slider">
              {testimonies.map((testimony, index) => (
                <Slide index={index} key={index}>
                  <TestimonyCard testimony={testimony} />
                </Slide>
              ))}
            </Slider>
          </div>
          <CarouselButtonContainer>
            <CarouselPreviousButton>
              <AppIcons.LeftChevron />
            </CarouselPreviousButton>
            <CarouselNextButton>
              <AppIcons.RightChevron />
            </CarouselNextButton>
          </CarouselButtonContainer>
        </CarouselProvider>
      </CarouselContainer>
      <TestimonyQuotes />
    </Container>
  );
};

const Container = styled.div`
  z-index: 1;
  padding-block: 4.125rem 11rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(16),
      theme.helpers.$rem(0)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(16),
      theme.helpers.$rem(176)
    )};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const TitleContainer = styled.div`
  margin-bottom: 4.125rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'margin-bottom',
      theme.helpers.$rem(20),
      theme.helpers.$rem(66)
    )};
  h1 {
    text-align: center;
  }
`;
const CarouselContainer = styled.div`
  max-width: 53.25rem;
  margin-inline: auto;
  position: relative;
  ${({ theme }) => theme.media.lg} {
    max-width: 650px;
  }
`;

const CarouselButtonContainer = styled.div`
  ${({ theme }) => theme.media.lg} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-block: 16px;
  }
`;

const baseCarouselButton = css`
  inset-block: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: transparent;

  svg path {
    fill: ${({ theme }) => theme.colors.darkPurple2};
  }

  ${({ theme }) => theme.media.lg} {
    svg {
      max-width: 10px;
      width: 100%;
    }
  }
  ${({ theme }) => theme.media.lg} {
    position: unset;
  }
`;
const CarouselPreviousButton = styled(ButtonBack)`
  ${baseCarouselButton};
  ${({ theme }) =>
    theme.fluid.screen(
      'left',
      theme.helpers.$rem(-12),
      theme.helpers.$rem(-48)
    )};

  ${({ theme }) => theme.media.lg} {
    position: unset;
  }
`;
const CarouselNextButton = styled(ButtonNext)`
  ${baseCarouselButton};
  ${({ theme }) =>
    theme.fluid.screen(
      'right',
      theme.helpers.$rem(-12),
      theme.helpers.$rem(-48)
    )};
`;

export default TestimonySection;
