import styled, { css } from 'styled-components';
import { Heading, Paragraph } from '../ui';
import { textGradients } from '../../theme/components/text-gradient';
import { AppIcons, AppVectors } from '../../assets';
import { Feature } from './data';

type FeatureProps = {
  feature: Feature;
  reverse?: boolean;
};
const FeatureItem = ({ feature, reverse }: FeatureProps) => {
  const { image, baseTitle, highlightedTitle, subtitle, list } = feature;
  return (
    <Container reverse={reverse}>
      <ImageContainer>
        <img src={image} alt="Feature Image Phone" />
      </ImageContainer>
      <Body>
        <TitleContainer>
          <Heading size="md" weight="bold">
            {baseTitle}
          </Heading>
          <Heading
            size="md"
            weight="bold"
            color="darkestGray"
            className="highlighted-text"
          >
            {highlightedTitle}
          </Heading>
          <AppVectors.StarLine />
        </TitleContainer>
        <SubtitleContainer>
          <Heading size="xs" weight="bold">
            {subtitle}
          </Heading>
        </SubtitleContainer>
        <ListContainer>
          {list.map((text, index) => (
            <ListItem key={index}>
              <AppIcons.Star />
              <Paragraph size="xl" weight="light">
                {text}
              </Paragraph>
            </ListItem>
          ))}
        </ListContainer>
      </Body>
    </Container>
  );
};

const reverseContainerStyle = css`
  flex-direction: row-reverse;
`;
const Container = styled.div<{ reverse?: boolean }>`
  display: flex;
  ${({ reverse }) => reverse && reverseContainerStyle};

  ${({ theme }) => theme.media.md} {
    flex-direction: column;
  }
  align-items: center;
`;

const ImageContainer = styled.div`
  margin: ${({ theme }) =>
    theme.fluid.between(
      -50,
      -80,
      theme.helpers.$rem(768),
      theme.helpers.$rem(1440)
    )};
  ${({ theme }) => theme.media.md} {
    //display: none
  }
  margin: ${({ theme }) =>
    theme.fluid.between(
      -0,
      -0,
      theme.helpers.$rem(300),
      theme.helpers.$rem(767)
    )};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50%;

  img {
    display: block;
    width: 120%;
    object-fit: contain;

    ${({ theme }) => theme.media.lg} {
      width: 70%;
      ${({ theme }) =>
        theme.fluid.screen(
          'width',
          theme.helpers.$rem(280),
          theme.helpers.$rem(500)
        )};
    }
  }
`;

const Body = styled.div`
  ${({ theme }) =>
    theme.fluid.screen(
      'max-width',
      theme.helpers.$rem(280),
      theme.helpers.$rem(512)
    )};
  width: 100%;
`;

const TitleContainer = styled.div`
  margin-bottom: 0.875rem;
  position: relative;

  h1 {
    ${({ theme }) =>
      theme.fluid.screen(
        'font-size',
        theme.helpers.$rem(16),
        theme.helpers.$rem(48)
      )};
  }

  .highlighted-text {
    ${textGradients.textGradient2};
  }

  svg {
    position: absolute;
    top: 45%;
    right: 0;
    width: 60%;
    ${({ theme }) => theme.media.md} {
      top: 40%;
    }
  }
  ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: center;
    h1 {
      text-align: center;
      display: inline;
      &:not(&:last-child) {
        margin-left: 8px;
      }
    }

    svg {
      display: none;
    }
  }
`;

const SubtitleContainer = styled.div`
  margin-bottom: 2rem;
  h1 {
    ${({ theme }) =>
      theme.fluid.screen(
        'font-size',
        theme.helpers.$rem(18),
        theme.helpers.$rem(28)
      )};
  }

  ${({ theme }) => theme.media.md} {
    h1 {
      text-align: center;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.125rem;
  ${({ theme }) => theme.media.md} {
    gap: 0.5rem;
    width: 100%;
    flex-direction: column;
    p {
      text-align: center;
    }
  }
`;
export default FeatureItem;
