import styled from 'styled-components';
import type { VariantButtonProps } from './types';
import BaseButton from './base';

const StrippedButton = (props: VariantButtonProps) => {
  return (
    <StrippedButtonWrapper>
      <BaseButton {...props} />
    </StrippedButtonWrapper>
  );
};

const StrippedButtonWrapper = styled.div`
  width: fit-content;
  position: relative;

  button {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    padding: 0;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default StrippedButton;
