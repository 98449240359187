export const appStack = {
  negativeTem: -10,
  negativeTwenty: -20,
  zero: 0,
  ten: 10,
  twenty: 20,
  thirty: 30,
  forty: 40,
  fifty: 50,
  max: 10000,
};
