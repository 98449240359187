import { AppVectors } from '../../assets';

export const thingsWeDo = [
  {
    Icon: AppVectors.Lend,
    title: 'We don’t do payday lending',
    description:
      'We only show you offers that can actually help you move forward.',
  },
  {
    Icon: AppVectors.Commission,
    title: 'We always show your best options',
    description:
      'We’ll only show you offers we think could help you achieve your goals – never because of the commission we earn.',
  },
  {
    Icon: AppVectors.Security,
    title: 'We don’t sell your data',
    description:
      'We’ll only use your data with your permission and we’ll never sell it to third parties.',
  },
];

export type ThingsWeDo = (typeof thingsWeDo)[number];
