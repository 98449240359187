const LinkedIn = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2857 24C22.75 24 23.1518 23.8304 23.4911 23.4911C23.8304 23.1518 24 22.75 24 22.2857V1.71429C24 1.25 23.8304 0.848215 23.4911 0.508929C23.1518 0.169643 22.75 0 22.2857 0H1.71429C1.25 0 0.848215 0.169643 0.508929 0.508929C0.169643 0.848215 0 1.25 0 1.71429V22.2857C0 22.75 0.169643 23.1518 0.508929 23.4911C0.848215 23.8304 1.25 24 1.71429 24H22.2857ZM5.46429 7.55357C4.89286 7.55357 4.41071 7.34821 4.01786 6.93751C3.625 6.52679 3.42857 6.04464 3.42857 5.49107C3.42857 4.9375 3.625 4.45536 4.01786 4.04464C4.41071 3.63393 4.89286 3.42857 5.46429 3.42857C6.03572 3.42857 6.52679 3.63393 6.93751 4.04464C7.34821 4.45536 7.55357 4.9375 7.55357 5.49107C7.55357 6.04464 7.34821 6.52679 6.93751 6.93751C6.52679 7.34821 6.03572 7.55357 5.46429 7.55357ZM13.0178 20.5714H9.48214V9.10714H12.9107V10.6607H12.9643C13.2143 10.1607 13.6071 9.75 14.1429 9.42858C14.7857 9.03572 15.5179 8.83929 16.3393 8.83929C17.9821 8.83929 19.1428 9.35714 19.8214 10.3929C20.3215 11.2143 20.5714 12.5179 20.5714 14.3036V20.5714H17.0357V15C17.0357 14.0714 16.9464 13.3929 16.7678 12.9643C16.5179 12.2857 15.9821 11.9464 15.1607 11.9464C14.3392 11.9464 13.75 12.25 13.3929 12.8571C13.1428 13.3214 13.0178 14 13.0178 14.8929V20.5714ZM7.28571 20.5714H3.69643V9.10714H7.28571V20.5714Z"
      fill="#0B081C"
    />
  </svg>
);

export default LinkedIn;
