export const statistics = [
  {
    label: 'People reached',
    value: '200K+',
  },
  {
    label: 'Global downloads',
    value: '10k',
  },
  {
    label: 'customers are already moving towards their financial goals',
    value: '70%',
  },
  {
    label: 'customers have seen a score increase in their first year of usage',
    value: '60%',
  },
  {
    label: '5-star reviews',
    value: '9k',
  },
];
export type Statistic = (typeof statistics)[number];
