import { AppIcons } from '../../assets';

export const socialLinks = [
  {
    Icon: AppIcons.LinkedIn,
    url: 'https://www.linkedin.com/company/zeeh-africa/',
  },
  {
    Icon: AppIcons.Twitter,
    url: 'https://twitter.com/zeeh_africa',
  },
  {
    Icon: AppIcons.Facebook,
    url: 'https://web.facebook.com/Zeehtechnologies',
  },
  {
    Icon: AppIcons.Youtube,
    url: 'https://www.youtube.com/channel/UCdpbyqW0rFHr_FqNtbMkA2Q',
  },
];

export type SocialLink = (typeof socialLinks)[number];

export const footerNavigation = [
  {
    title: 'Services',
    links: [
      {
        label: 'Credit Report',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Credit Cards',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Loans',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Mortgages',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
    ],
  },
  {
    title: 'Company',
    links: [
      {
        label: 'About Us',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Careers',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Contact',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Complaints',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
    ],
  },
  {
    title: 'Guidance',
    links: [
      {
        label: 'FAQs',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Guides',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Blog',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
      {
        label: 'Press',
        url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
      },
    ],
  },
  {
    title: 'Legal',
    links: [
      {
        label: 'Privacy',
        url: 'https://apps.apple.com/us/app/usezeeh/id6458531408',
      },
      {
        label: 'T&Cs',
        url: 'https://apps.apple.com/us/app/usezeeh/id6458531408',
      },
      {
        label: 'Cookies',
        url: 'https://apps.apple.com/us/app/usezeeh/id6458531408',
      },
    ],
  },
];

export type FooterNavigationItem = (typeof footerNavigation)[number];
