import StrippedButton from './stripped';
import FilledButton from './filled';

import type { VariantButtonProps } from './types';

const buttonVariants = {
  filled: FilledButton,
  stripped: StrippedButton,
} as const;

type Variant = keyof typeof buttonVariants;

type ButtonProps = { variant?: Variant } & VariantButtonProps;

export const Button = ({
  variant = 'filled',
  href,
  ...otherProps
}: ButtonProps) => {
  const VariantButtonComponent = buttonVariants[variant];

  return (
    <>
      {href ? (
          <VariantButtonComponent {...otherProps} as="a" href={href} />
      ) : (
        <VariantButtonComponent {...otherProps} />
      )}
    </>
  );
};

export default Button;
