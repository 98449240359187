import { css } from 'styled-components';

const baseTextStyles = css`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
export const textGradients = {
  textGradient1: css`
    background: ${({ theme }) => theme.gradients.gradient1};
    ${baseTextStyles};
  `,
  textGradient2: css`
    background: ${({ theme }) => theme.gradients.gradient2};
    ${baseTextStyles};
  `,
  textGradient3: css`
    background: ${({ theme }) => theme.gradients.gradient3};
    ${baseTextStyles};
  `,
};
