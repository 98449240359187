export const testimonies = [
  {
    author: 'Adebola',
    body: "\"As a tool for understanding your financial situation and your borrowing power I rate this app as an honest and supportive platform. Top rated in my opinion and not biased about reality in money situation answers.\"",
    rating: 5,
  },
  {
    author: 'Michael',
    body: "\"As a tool for understanding your financial situation and your borrowing power I rate this app as an honest and supportive platform. Top rated in my opinion and not biased about reality in money situation answers.\"",
    rating: 5,
  },
  {
    author: 'Joshua',
    body: "\"As a tool for understanding your financial situation and your borrowing power I rate this app as an honest and supportive platform. Top rated in my opinion and not biased about reality in money situation answers.\"",
    rating: 5,
  },
  {
    author: 'Anakin Skywalker',
    body: "\"As a tool for understanding your financial situation and your borrowing power I rate this app as an honest and supportive platform. Top rated in my opinion and not biased about reality in money situation answers.\"",
    rating: 5,
  },
  {
    author: 'Master Oogwe',
    body: "\"As a tool for understanding your financial situation and your borrowing power I rate this app as an honest and supportive platform. Top rated in my opinion and not biased about reality in money situation answers.\"",
    rating: 5,
  },
];

export type Testimony = (typeof testimonies)[number];
