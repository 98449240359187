import { appImages } from '../assets';
import { Paragraph } from './ui';
import styled from 'styled-components';

const AppLogo = () => (
  <LogoContainer>
    <img src={appImages.logo} alt={appImages.logo} />
    <Paragraph size="lg" weight="bold" color="white">
      UseZeeh
    </Paragraph>
  </LogoContainer>
);

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  p {
    margin-bottom: 0 !important;
  }
  svg {
    width: 100%;
  }
  ${({ theme }) =>
    theme.fluid.screen(
      'max-width',
      theme.helpers.$rem(140),
      theme.helpers.$rem(140)
    )};
  img {
    display: block;
    max-width: 40px;
    width: 100%
      ${({ theme }) =>
        theme.fluid.screen(
          'max-width',
          theme.helpers.$rem(50),
          theme.helpers.$rem(60)
        )};
  }
`;

export default AppLogo;
