import styled from 'styled-components';
import { Testimony } from './data';
import { Paragraph } from '../ui';
import { AppIcons } from '../../assets';

type TestimonyCardProps = {
  testimony: Testimony;
};
const TestimonyCard = ({ testimony }: TestimonyCardProps) => {
  const { author, body, rating } = testimony;
  return (
    <OuterContainer>
      <Container>
        <RatingContainer>
          {[...Array(rating)].map((e, index) => (
            <AppIcons.Star key={index} />
          ))}
        </RatingContainer>
        <BodyContainer>
          <Paragraph size="lg" weight="light">
            {body}
          </Paragraph>
        </BodyContainer>
        <AuthorContainer>
          <Paragraph size="lg" weight="medium">
            {author}
          </Paragraph>
        </AuthorContainer>
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  aspect-ratio: 884/254;
  max-height: 100%;
  padding-inline: 16px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div`
  width: 100%;
  padding: 3rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'padding',
      theme.helpers.$rem(16),
      theme.helpers.$rem(48)
    )};
  box-shadow: 0px 2px 8px rgba(8, 5, 28, 0.08);
  aspect-ratio: 852/254;

  ${({ theme }) => theme.media.lg} {
    aspect-ratio: 852/300;
  }
  background-color: ${({ theme }) => theme.colors.pageBg};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
`;
const BodyContainer = styled.div`
  p {
    ${({ theme }) =>
      theme.fluid.screen(
        'font-size',
        theme.helpers.$rem(14),
        theme.helpers.$rem(20)
      )};
  }
`;
const AuthorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export default TestimonyCard;
