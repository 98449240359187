export const navigationLinks = [
  {
    label: 'Credit report',
    url: 'https://apps.apple.com/us/app/usezeeh/id6458531408',
  },
  {
    label: 'Credit card',
    url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
  },
  {
    label: 'Loans',
    url: 'https://apps.apple.com/us/app/usezeeh/id6458531408',
  },
  {
    label: 'Mortgages',
    url: 'https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share',
  },
];

export type NavigationLink = (typeof navigationLinks)[number];
