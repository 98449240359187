import styled from 'styled-components';
import { Offer } from './data';
import { Paragraph } from '../ui';

type OfferCardProps = {
  offer: Offer;
};
const OfferCard = ({ offer }: OfferCardProps) => {
  const { title, Icon } = offer;
  return (
    <Container>
      <Icon />
      <Paragraph size="lg" weight="bold">
        {title}
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  max-width: 17.875rem;
  width: 100%;
  aspect-ratio: 286/242;
  background-color: ${({ theme }) => theme.colors.pageBg};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  svg {
    ${({ theme }) =>
      theme.fluid.screen(
        'max-width',
        theme.helpers.$rem(130),
        theme.helpers.$rem(184)
      )};
  }
`;

export default OfferCard;
