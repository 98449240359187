import { AppVectors } from '../../assets';
import styled, { css } from 'styled-components';

const HeroQuotes = () => (
  <>
    <LeftTopQuoteContainer>
      <AppVectors.LargeQuotes />
    </LeftTopQuoteContainer>
    <RightTopQuoteContainer>
      <AppVectors.LargeQuotes />
    </RightTopQuoteContainer>
    <LeftBottomQuoteContainer>
      <AppVectors.LargeQuotes />
    </LeftBottomQuoteContainer>
    <RightBottomQuoteContainer>
      <AppVectors.LargeQuotes />
    </RightBottomQuoteContainer>
  </>
);

const baseQuotesStyles = css`
  width: 100%;
  max-width: 12.5rem;
  aspect-ratio: 200/189;
  position: absolute;
  opacity: .8;
  ${({ theme }) =>
          theme.fluid.screen(
                  'max-width',
                  theme.helpers.$rem(50),
                  theme.helpers.$rem(200)
          )};
  svg {
    width: 100%;
  }
  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;
const LeftTopQuoteContainer = styled.div`
  ${baseQuotesStyles};
  left: 0.875rem;
  top: 1rem;
  ${({ theme }) =>
          theme.fluid.screen('top', theme.helpers.$rem(0), theme.helpers.$rem(16))};
`;

const RightTopQuoteContainer = styled.div`
  ${baseQuotesStyles};
  right: 0.875rem;
  ${({ theme }) =>
          theme.fluid.screen('top', theme.helpers.$rem(0), theme.helpers.$rem(16))};
  svg {
    transform: rotate(90deg);
  }
`;

const LeftBottomQuoteContainer = styled.div`
  ${baseQuotesStyles};
  left: 0.875rem;
  ${({ theme }) =>
          theme.fluid.screen(
                  'bottom',
                  theme.helpers.$rem(0),
                  theme.helpers.$rem(40)
          )};
  svg {
    transform: rotate(270deg);
  }
`;

const RightBottomQuoteContainer = styled.div`
  ${baseQuotesStyles};
  right: 0.875rem;
  ${({ theme }) =>
          theme.fluid.screen(
                  'bottom',
                  theme.helpers.$rem(0),
                  theme.helpers.$rem(40)
          )};
  svg {
    transform: rotate(180deg);
  }
`;

export default HeroQuotes;
