import { rem } from '../helpers/font';

const sizes = {
  textSm: {
    sm: rem(14),
    lg: rem(14),
  },
  textMd: {
    sm: rem(16),
    lg: rem(16),
  },
  textLg: {
    sm: rem(16),
    lg: rem(18),
  },
  textXl: {
    sm: rem(16),
    lg: rem(20),
  },
  textXxl: {
    sm: rem(16),
    lg: rem(22),
  },

  titleMin: {
    sm: rem(22),
    lg: rem(22),
  },
  titleXs: {
    sm: rem(24),
    lg: rem(28),
  },
  titleSm: {
    sm: rem(24),
    lg: rem(36),
  },
  titleMd: {
    sm: rem(24),
    lg: rem(48),
  },
  titleLg: {
    sm: rem(24),
    lg: rem(60),
  },
  titleXl: {
    sm: rem(24),
    lg: rem(64),
  },
} as const;

const family = {
  main: "'DM Sans', sans-serif "
} as const;

const styles = {
  normal: 'normal',
  italic: 'italic',
} as const;

export const appFont = {
  family,
  sizes,
  styles,
} as const;
