import styled from 'styled-components';
import { NavigationLink } from './data';
import { Paragraph } from '../ui';

type NavigationItemProps = {
  navigationLinks: NavigationLink[];
};
const NavigationItem = ({ navigationLinks }: NavigationItemProps) => {
  return (
    <Container>
      {navigationLinks.map(({ label, url }, index) => (
        <Anchor key={index} href={url}>
          {label}
        </Anchor>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) =>
    theme.fluid.screen('gap', theme.helpers.$rem(14), theme.helpers.$rem(36))};

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.helpers.$rem(14),
      theme.helpers.$rem(16)
    )};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default NavigationItem;
