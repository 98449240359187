import styled from 'styled-components';
import { Heading } from '../ui';
import { textGradients } from '../../theme/components/text-gradient';
import OfferCard from './card';
import { offers } from './data';

const OfferSection = () => {
  return (
    <Container>
      <TitleContainer>
        <Heading size="md" weight="bold" as="span">
          Our &nbsp;
        </Heading>
        <Heading size="md" weight="bold" as="span" className="highlighted-text">
          Offers
        </Heading>
      </TitleContainer>
      <OfferCardContainer>
        {offers.map((offer, index) => (
          <OfferCard key={index} offer={offer} />
        ))}
      </OfferCardContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 4.375rem 1rem;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  .highlighted-text {
    ${textGradients.textGradient2};
  }
`;

const OfferCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
`;
export default OfferSection;
