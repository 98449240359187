const Twitter = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.54686 19.5C10.4219 19.5 12.9687 18.7656 15.1875 17.2968C17.2187 15.9843 18.7968 14.2187 19.9218 12C21.0156 9.9062 21.5624 7.73436 21.5624 5.48436C21.5624 5.20311 21.5468 4.99999 21.5156 4.87499C22.4843 4.15624 23.3124 3.29687 23.9999 2.29687C23.0624 2.70312 22.1249 2.96874 21.1875 3.09374C22.2499 2.43749 22.9687 1.53125 23.3437 0.375C22.3749 0.9375 21.3281 1.32812 20.2031 1.54687C19.7343 1.07812 19.1953 0.70312 18.5859 0.42187C17.9765 0.14062 17.3125 0 16.5937 0C15.7187 0 14.9062 0.21875 14.1562 0.65625C13.4062 1.09375 12.8125 1.69531 12.375 2.46093C11.9375 3.22655 11.7187 4.04686 11.7187 4.92186C11.7187 5.29686 11.75 5.67186 11.8125 6.04686C9.81248 5.95311 7.92967 5.45311 6.16405 4.54686C4.39843 3.64062 2.90624 2.42187 1.6875 0.89062C1.21875 1.67187 0.984373 2.49218 0.984373 3.35155C0.984373 4.21093 1.1875 4.99999 1.59375 5.71874C2 6.43748 2.53124 7.03123 3.18749 7.49998C2.40624 7.46873 1.67187 7.24998 0.984373 6.84373V6.93748C0.984373 8.0937 1.35937 9.125 2.10937 10.0312C2.85937 10.9375 3.79687 11.5156 4.92186 11.7656C4.48436 11.8593 4.04686 11.9062 3.60937 11.9062C3.32812 11.9062 3.03124 11.8906 2.71874 11.8593C3.03124 12.8281 3.60155 13.6328 4.42968 14.2734C5.2578 14.914 6.21874 15.2343 7.31248 15.2343C5.49999 16.6406 3.45312 17.3437 1.17187 17.3437C0.734374 17.3437 0.343749 17.3281 0 17.2968C2.28124 18.7656 4.79686 19.5 7.54686 19.5Z"
      fill="#0B081C"
    />
  </svg>
);

export default Twitter;
