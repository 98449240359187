import { memo } from 'react';

import _AppleStore from './apple-store';
import _Card from './card';
import _Commission from './commission';
import _Key from './key';
import _LargeQuotes from './large-quotes';
import _Lend from './lend';
import _LineWave from './line-wave';
import _Loan from './loan';
import _Logo from './logo';
import _Mortgage from './mortgage';
import _PlayStore from './play-store';
import _Security from './security';
import _StarLine from './star-line';
import _StarLineDouble from './star-line-double';

const AppleStore = memo(_AppleStore);
const Card = memo(_Card);
const Commission = memo(_Commission);
const LargeQuotes = memo(_LargeQuotes);
const Key = memo(_Key);
const Lend = memo(_Lend);
const LineWave = memo(_LineWave);
const Loan = memo(_Loan);
const Logo = memo(_Logo);
const PlayStore = memo(_PlayStore);
const Mortgage = memo(_Mortgage);
const Security = memo(_Security);
const StarLine = memo(_StarLine);
const StarLineDouble = memo(_StarLineDouble);

const DownloadOptions = {
  AppleStore,
  PlayStore,
} as const;

const AppVectors = {
  Card,
  Commission,
  DownloadOptions,
  Key,
  LargeQuotes,
  Lend,
  LineWave,
  Loan,
  Logo,
  Mortgage,
  Security,
  StarLine,
  StarLineDouble,
} as const;

export default AppVectors;
