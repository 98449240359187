import { createGlobalStyle } from 'styled-components';

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    font-size: ${({ theme }) => theme.font.sizes.textMd.lg};
    font-family: ${({ theme }) => theme.font.family.main};
    color: ${({ theme }) => theme.colors.darkestGray};
    background-color: ${({ theme }) => theme.colors.pageBg};
  }
  * {
    box-sizing: border-box;
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-block: 0;
  }
`;

export default GlobalStyles;
