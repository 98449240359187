const RightChevron = () => (
  <svg
    width="16"
    height="28"
    viewBox="0 0 16 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.956 14L0.611995 3.58998C0.219825 3.19444 -0.000213623 2.65999 -0.000213623 2.10298C-0.000213623 1.54598 0.219825 1.01153 0.611995 0.615983C0.8054 0.420666 1.0356 0.265619 1.28929 0.159806C1.54298 0.0539929 1.81512 -0.000488281 2.08999 -0.000488281C2.36487 -0.000488281 2.63701 0.0539929 2.8907 0.159806C3.14439 0.265619 3.37459 0.420666 3.56799 0.615983L15.388 12.512C16.204 13.334 16.204 14.666 15.388 15.488L3.56799 27.384C3.37459 27.5793 3.14439 27.7343 2.8907 27.8402C2.63701 27.946 2.36487 28.0005 2.08999 28.0005C1.81512 28.0005 1.54298 27.946 1.28929 27.8402C1.0356 27.7343 0.8054 27.5793 0.611995 27.384C0.219825 26.9884 -0.000213623 26.454 -0.000213623 25.897C-0.000213623 25.34 0.219825 24.8055 0.611995 24.41L10.956 14Z"
      fill="#360B81"
    />
  </svg>
);

export default RightChevron;
