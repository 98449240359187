import styled from 'styled-components';
import { Heading } from '../ui';
import WhatWeDoItem from './item';
import { thingsWeDo } from './data';

const WhatWeDoSection = () => {
  return (
    <Container>
      <TitleContainer>
        <Heading size="sm" weight="bold">
          What do we do?
        </Heading>
      </TitleContainer>
      <WhatWeDoItemContainer>
        {thingsWeDo.map((thing, index) => (
          <WhatWeDoItem key={index} things={thing} />
        ))}
      </WhatWeDoItemContainer>
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) =>
          theme.fluid.screen('padding-inline', theme.helpers.$rem(24), theme.helpers.$rem(80))};
 ${({ theme }) =>
          theme.fluid.screen('padding-block', theme.helpers.$rem(48), theme.helpers.$rem(64))};

  max-width: 100rem;
  margin-inline: auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const WhatWeDoItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  ${({ theme }) =>
          theme.fluid.screen('gap', theme.helpers.$rem(40), theme.helpers.$rem(60))};
`;

export default WhatWeDoSection;
