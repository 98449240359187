const AppleStore = () => (
  <svg
    width="120"
    height="40"
    viewBox="0 0 120 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M110.443 0H9.55708C9.19605 0 8.83503 0 8.46398 0C8.1531 0 7.85225 0.01 7.54137 0.01C6.86946 0.03 6.19756 0.07 5.53568 0.19C4.86378 0.31 4.24202 0.51 3.63029 0.82C3.02858 1.12 2.47702 1.52 2.00568 2C1.52432 2.47 1.12318 3.02 0.82233 3.62C0.511449 4.23 0.310881 4.85 0.19054 5.52C0.0701989 6.18 0.0300852 6.85 0.0100284 7.52C0.0100284 7.83 0 8.14 0 8.44C0 8.8 0 9.17 0 9.53V30.46C0 30.83 0 31.19 0 31.55C0 31.86 0.0100284 32.16 0.0200568 32.47C0.0401137 33.14 0.0802273 33.81 0.200568 34.47C0.320909 35.14 0.511449 35.77 0.832358 36.37C1.13321 36.97 1.53435 37.51 2.01571 37.98C2.48705 38.46 3.03861 38.86 3.64031 39.16C4.25205 39.47 4.87381 39.67 5.54571 39.79C6.20759 39.91 6.88952 39.95 7.5514 39.97C7.85225 39.99 8.1531 40 8.46398 40C8.83503 40 9.19605 40 9.55708 40H110.443C110.804 40 111.165 40 111.526 40C111.827 40 112.148 40 112.449 39.99C113.12 39.97 113.792 39.93 114.454 39.81C115.126 39.69 115.748 39.49 116.37 39.18C116.971 38.88 117.513 38.48 117.994 38C118.476 37.53 118.867 36.98 119.178 36.39C119.489 35.78 119.689 35.16 119.799 34.49C119.92 33.83 119.96 33.16 119.99 32.49C119.99 32.18 119.99 31.88 119.99 31.57C120 31.21 120 30.85 120 30.48V9.54C120 9.17 120 8.81 119.99 8.45C119.99 8.14 119.99 7.84 119.99 7.53C119.97 6.86 119.93 6.19 119.799 5.53C119.689 4.86 119.489 4.24 119.178 3.63C118.867 3.03 118.466 2.48 117.994 2.01C117.523 1.54 116.971 1.14 116.37 0.83C115.748 0.52 115.126 0.32 114.454 0.2C113.792 0.08 113.12 0.04 112.449 0.02C112.148 0.02 111.827 0.01 111.526 0.01C111.175 0 110.804 0 110.443 0Z"
      fill="#B7B7B7"
    />
    <path
      d="M8.46407 39.1201C8.16321 39.1201 7.86236 39.1201 7.56151 39.1101C6.99992 39.0901 6.33804 39.0601 5.6862 38.9501C5.07446 38.8401 4.53293 38.6601 4.02148 38.4001C3.5 38.1401 3.02867 37.7901 2.6175 37.3801C2.20634 36.9701 1.86537 36.5101 1.5946 35.9801C1.33387 35.4801 1.15335 34.9301 1.05307 34.3201C0.932729 33.6501 0.902644 32.9601 0.882587 32.4401C0.872559 32.2301 0.872559 31.5301 0.872559 31.5301V8.44012C0.872559 8.44012 0.882587 7.75012 0.882587 7.55012C0.902644 7.03012 0.932729 6.34012 1.05307 5.68012C1.16338 5.06012 1.34389 4.52012 1.60463 4.02012C1.8754 3.50012 2.21637 3.03012 2.62753 2.62012C3.0387 2.20012 3.51003 1.86012 4.02148 1.60012C4.53293 1.34012 5.08449 1.16012 5.67617 1.06012C6.3581 0.930127 7.04003 0.900127 7.56151 0.890127L8.46407 0.880127H111.526L112.439 0.890127C112.95 0.900127 113.642 0.930127 114.304 1.05012C114.906 1.16012 115.457 1.33012 115.979 1.60012C116.49 1.86012 116.961 2.21012 117.373 2.62012C117.784 3.03012 118.125 3.50012 118.396 4.02012C118.656 4.53012 118.827 5.07012 118.937 5.67012C119.057 6.30012 119.087 6.95012 119.108 7.56012C119.108 7.84012 119.108 8.15012 119.108 8.45012C119.118 8.83012 119.118 9.18013 119.118 9.54012V30.4701C119.118 30.8301 119.118 31.1901 119.108 31.5501C119.108 31.8801 119.108 32.1701 119.108 32.4801C119.087 33.0701 119.047 33.7201 118.937 34.3301C118.837 34.9401 118.656 35.4901 118.396 36.0001C118.125 36.5201 117.784 36.9901 117.373 37.3901C116.961 37.8101 116.49 38.1501 115.969 38.4101C115.447 38.6701 114.916 38.8501 114.294 38.9601C113.652 39.0801 112.99 39.1101 112.419 39.1201C112.128 39.1301 111.817 39.1301 111.516 39.1301H110.433L8.46407 39.1201Z"
      fill="#000001"
    />
    <path
      d="M24.8403 20.3001C24.8102 17.5501 27.0967 16.2101 27.207 16.1501C25.9134 14.2701 23.9077 14.0101 23.2057 13.9901C21.5209 13.8101 19.8863 14.9901 19.0339 14.9901C18.1614 14.9901 16.8376 14.0001 15.4136 14.0301C13.5784 14.0601 11.8636 15.1201 10.9309 16.7601C8.99543 20.1101 10.4395 25.0301 12.2948 27.7401C13.2274 29.0701 14.3105 30.5501 15.7345 30.4901C17.1285 30.4301 17.6499 29.6101 19.3247 29.6101C20.9894 29.6101 21.4708 30.4901 22.9249 30.4601C24.4191 30.4401 25.3618 29.1301 26.2543 27.7901C27.3274 26.2701 27.7586 24.7701 27.7786 24.7001C27.7285 24.6901 24.8704 23.6001 24.8403 20.3001Z"
      fill="white"
    />
    <path
      d="M22.1027 12.21C22.8548 11.28 23.3663 10.01 23.2158 8.71997C22.1328 8.76997 20.7789 9.46997 19.9967 10.38C19.3048 11.18 18.693 12.5 18.8535 13.74C20.0669 13.83 21.3205 13.13 22.1027 12.21Z"
      fill="white"
    />
    <path
      d="M42.4202 27.1401H37.6768L36.5335 30.5001H34.5278L39.0206 18.0801H41.1065L45.5992 30.5001H43.5534L42.4202 27.1401ZM38.1681 25.5901H41.9288L40.0735 20.1401H40.0234L38.1681 25.5901Z"
      fill="white"
    />
    <path
      d="M55.3167 25.9701C55.3167 28.7801 53.8024 30.5901 51.5259 30.5901C50.2323 30.5901 49.2094 30.0101 48.6678 29.0101H48.6277V33.4901H46.7625V21.4401H48.5676V22.9501H48.5976C49.1191 21.9801 50.2223 21.3501 51.4858 21.3501C53.8024 21.3501 55.3167 23.1601 55.3167 25.9701ZM53.4013 25.9701C53.4013 24.1401 52.4486 22.9301 51.0045 22.9301C49.5804 22.9301 48.6177 24.1601 48.6177 25.9701C48.6177 27.7901 49.5804 29.0201 51.0045 29.0201C52.4486 29.0201 53.4013 27.8201 53.4013 25.9701Z"
      fill="white"
    />
    <path
      d="M65.305 25.9701C65.305 28.7801 63.7907 30.5901 61.5142 30.5901C60.2205 30.5901 59.1976 30.0101 58.6561 29.0101H58.616V33.4901H56.7607V21.4401H58.5659V22.9501H58.5959C59.1174 21.9801 60.2205 21.3501 61.4841 21.3501C63.7907 21.3501 65.305 23.1601 65.305 25.9701ZM63.3895 25.9701C63.3895 24.1401 62.4368 22.9301 60.9927 22.9301C59.5687 22.9301 58.606 24.1601 58.606 25.9701C58.606 27.7901 59.5687 29.0201 60.9927 29.0201C62.4469 29.0201 63.3895 27.8201 63.3895 25.9701Z"
      fill="white"
    />
    <path
      d="M71.9137 27.0401C72.0541 28.2701 73.2475 29.0801 74.8921 29.0801C76.4666 29.0801 77.5898 28.2701 77.5898 27.1601C77.5898 26.2001 76.9079 25.6201 75.2933 25.2201L73.6787 24.8301C71.3922 24.2801 70.3292 23.2101 70.3292 21.4801C70.3292 19.3401 72.2045 17.8701 74.8621 17.8701C77.4895 17.8701 79.2946 19.3401 79.3548 21.4801H77.4694C77.3591 20.2401 76.3262 19.4901 74.832 19.4901C73.3377 19.4901 72.3048 20.2501 72.3048 21.3501C72.3048 22.2301 72.9567 22.7401 74.5612 23.1401L75.9351 23.4801C78.4923 24.0801 79.5554 25.1101 79.5554 26.9201C79.5554 29.2401 77.7001 30.7001 74.7517 30.7001C71.9939 30.7001 70.1286 29.2801 70.0083 27.0301H71.9137V27.0401Z"
      fill="white"
    />
    <path
      d="M83.5868 19.3V21.4401H85.3116V22.91H83.5868V27.9C83.5868 28.68 83.9277 29.0401 84.6899 29.0401C84.8804 29.0401 85.1813 29.01 85.3016 29V30.46C85.091 30.51 84.6798 30.55 84.2687 30.55C82.4335 30.55 81.7114 29.8601 81.7114 28.1101V22.92H80.3877V21.4501H81.7114V19.3H83.5868Z"
      fill="white"
    />
    <path
      d="M86.3044 25.9701C86.3044 23.1201 87.9892 21.3301 90.6066 21.3301C93.2341 21.3301 94.9088 23.1201 94.9088 25.9701C94.9088 28.8301 93.2441 30.6101 90.6066 30.6101C87.9792 30.6101 86.3044 28.8301 86.3044 25.9701ZM93.0235 25.9701C93.0235 24.0201 92.1209 22.8601 90.6167 22.8601C89.1124 22.8601 88.2098 24.0201 88.2098 25.9701C88.2098 27.9301 89.1024 29.0801 90.6167 29.0801C92.131 29.0801 93.0235 27.9301 93.0235 25.9701Z"
      fill="white"
    />
    <path
      d="M96.4634 21.4401H98.2384V22.9801H98.2785C98.5593 21.9601 99.3917 21.3401 100.465 21.3401C100.735 21.3401 100.956 21.3801 101.107 21.4101V23.1501C100.956 23.0901 100.635 23.0401 100.274 23.0401C99.0708 23.0401 98.3287 23.8501 98.3287 25.1201V30.4901H96.4634V21.4401Z"
      fill="white"
    />
    <path
      d="M109.691 27.8401C109.44 29.4801 107.836 30.6101 105.78 30.6101C103.142 30.6101 101.498 28.8501 101.498 26.0101C101.498 23.1701 103.142 21.3301 105.699 21.3301C108.207 21.3301 109.791 23.0501 109.791 25.8001V26.4401H103.383V26.5501C103.383 28.1001 104.356 29.1101 105.83 29.1101C106.863 29.1101 107.675 28.6201 107.926 27.8401H109.691ZM103.393 25.1301H107.936C107.896 23.7401 107.003 22.8301 105.709 22.8301C104.426 22.8401 103.493 23.7701 103.393 25.1301Z"
      fill="white"
    />
    <path
      d="M37.9374 8.72998C39.7124 8.72998 40.7554 9.81998 40.7554 11.69C40.7554 13.6 39.7224 14.69 37.9374 14.69H35.7712V8.72998H37.9374ZM36.7039 13.85H37.8271C39.0806 13.85 39.8027 13.07 39.8027 11.7C39.8027 10.35 39.0706 9.56998 37.8271 9.56998H36.7039V13.85Z"
      fill="white"
    />
    <path
      d="M41.7983 12.4401C41.7983 10.9901 42.6106 10.1001 43.9244 10.1001C45.2381 10.1001 46.0504 10.9801 46.0504 12.4401C46.0504 13.9001 45.2381 14.7801 43.9244 14.7801C42.6106 14.7801 41.7983 13.9001 41.7983 12.4401ZM45.1378 12.4401C45.1378 11.4601 44.6965 10.8901 43.9244 10.8901C43.1522 10.8901 42.7109 11.4601 42.7109 12.4401C42.7109 13.4201 43.1421 13.9901 43.9244 13.9901C44.7066 13.9901 45.1378 13.4201 45.1378 12.4401Z"
      fill="white"
    />
    <path
      d="M51.7164 14.7H50.7937L49.8611 11.38H49.7909L48.8583 14.7H47.9457L46.7021 10.2H47.6047L48.417 13.64H48.4872L49.4198 10.2H50.2723L51.2049 13.64H51.2751L52.0774 10.2H52.9699L51.7164 14.7Z"
      fill="white"
    />
    <path
      d="M54.0029 10.1901H54.8654V10.9101H54.9356C55.1562 10.4101 55.6075 10.1101 56.2794 10.1101C57.2822 10.1101 57.8438 10.7101 57.8438 11.7801V14.7001H56.9513V12.0101C56.9513 11.2901 56.6404 10.9301 55.9785 10.9301C55.3167 10.9301 54.8955 11.3701 54.8955 12.0701V14.7001H54.0029V10.1901Z"
      fill="white"
    />
    <path d="M59.2578 8.43994H60.1503V14.6999H59.2578V8.43994Z" fill="white" />
    <path
      d="M61.3938 12.4401C61.3938 10.9901 62.2061 10.1001 63.5198 10.1001C64.8335 10.1001 65.6458 10.9801 65.6458 12.4401C65.6458 13.9001 64.8335 14.7801 63.5198 14.7801C62.1961 14.7801 61.3938 13.9001 61.3938 12.4401ZM64.7333 12.4401C64.7333 11.4601 64.292 10.8901 63.5198 10.8901C62.7476 10.8901 62.3064 11.4601 62.3064 12.4401C62.3064 13.4201 62.7376 13.9901 63.5198 13.9901C64.292 13.9901 64.7333 13.4201 64.7333 12.4401Z"
      fill="white"
    />
    <path
      d="M66.5886 13.4201C66.5886 12.6101 67.1903 12.1401 68.2634 12.0801L69.4868 12.0101V11.6201C69.4868 11.1401 69.176 10.8801 68.5642 10.8801C68.0628 10.8801 67.7218 11.0601 67.6215 11.3801H66.7591C66.8494 10.6101 67.5814 10.1101 68.6043 10.1101C69.7375 10.1101 70.3794 10.6701 70.3794 11.6201V14.7001H69.5169V14.0701H69.4467C69.176 14.5201 68.6846 14.7801 68.0929 14.7801C67.2204 14.7701 66.5886 14.2501 66.5886 13.4201ZM69.4969 13.0401V12.6601L68.3937 12.7301C67.772 12.7701 67.4912 12.9801 67.4912 13.3801C67.4912 13.7901 67.8422 14.0201 68.3235 14.0201C68.9954 14.0201 69.4969 13.6001 69.4969 13.0401Z"
      fill="white"
    />
    <path
      d="M71.5527 12.4399C71.5527 11.0199 72.2848 10.1199 73.4281 10.1199C74.0498 10.1199 74.5713 10.4099 74.812 10.9099H74.8822V8.43994H75.7747V14.6999H74.9123V13.9899H74.8421C74.5713 14.4799 74.0498 14.7799 73.4281 14.7799C72.2748 14.7699 71.5527 13.8699 71.5527 12.4399ZM72.4753 12.4399C72.4753 13.3999 72.9266 13.9699 73.6788 13.9699C74.4309 13.9699 74.8922 13.3899 74.8922 12.4399C74.8922 11.4999 74.4209 10.9099 73.6788 10.9099C72.9266 10.9199 72.4753 11.4999 72.4753 12.4399Z"
      fill="white"
    />
    <path
      d="M79.4551 12.4401C79.4551 10.9901 80.2674 10.1001 81.5811 10.1001C82.8948 10.1001 83.7071 10.9801 83.7071 12.4401C83.7071 13.9001 82.8948 14.7801 81.5811 14.7801C80.2674 14.7801 79.4551 13.9001 79.4551 12.4401ZM82.7945 12.4401C82.7945 11.4601 82.3533 10.8901 81.5811 10.8901C80.8089 10.8901 80.3677 11.4601 80.3677 12.4401C80.3677 13.4201 80.7989 13.9901 81.5811 13.9901C82.3533 13.9901 82.7945 13.4201 82.7945 12.4401Z"
      fill="white"
    />
    <path
      d="M84.9104 10.1901H85.7728V10.9101H85.843C86.0637 10.4101 86.515 10.1101 87.1869 10.1101C88.1897 10.1101 88.7513 10.7101 88.7513 11.7801V14.7001H87.8588V12.0101C87.8588 11.2901 87.5479 10.9301 86.886 10.9301C86.2241 10.9301 85.8029 11.3701 85.8029 12.0701V14.7001H84.9104V10.1901Z"
      fill="white"
    />
    <path
      d="M93.7857 9.07007V10.21H94.7685V10.96H93.7857V13.28C93.7857 13.75 93.9762 13.96 94.4275 13.96C94.5679 13.96 94.6381 13.95 94.7685 13.94V14.68C94.6281 14.7 94.4576 14.73 94.2871 14.73C93.2943 14.73 92.9032 14.38 92.9032 13.51V10.97H92.1912V10.22H92.9032V9.07007H93.7857Z"
      fill="white"
    />
    <path
      d="M95.9719 8.43994H96.8544V10.9199H96.9246C97.1453 10.4199 97.6166 10.1099 98.2985 10.1099C99.2612 10.1099 99.8529 10.7199 99.8529 11.7899V14.6999H98.9604V12.0099C98.9604 11.2899 98.6295 10.9299 97.9977 10.9299C97.2656 10.9299 96.8645 11.3899 96.8645 12.0699V14.6999H95.9719V8.43994Z"
      fill="white"
    />
    <path
      d="M105.058 13.4801C104.857 14.2901 104.135 14.7801 103.102 14.7801C101.808 14.7801 101.016 13.9001 101.016 12.4601C101.016 11.0201 101.828 10.1101 103.102 10.1101C104.356 10.1101 105.118 10.9701 105.118 12.3801V12.6901H101.929V12.7401C101.959 13.5301 102.42 14.0301 103.132 14.0301C103.674 14.0301 104.045 13.8401 104.205 13.4801H105.058ZM101.929 12.0301H104.205C104.185 11.3201 103.754 10.8601 103.092 10.8601C102.43 10.8601 101.979 11.3301 101.929 12.0301Z"
      fill="white"
    />
  </svg>
);

export default AppleStore;
