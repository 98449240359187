import { customMediaQuery } from '../helpers/media';
import { rem } from '../helpers/font';

export const mediaSizes = {
  min: rem(375),
  sm: rem(592),
  md: rem(768),
  lg: rem(992),
  xl: rem(1024),
  xxl: rem(1200),
  max: rem(1440),
} as const;

export const appMedia = {
  custom: customMediaQuery,
  min: customMediaQuery(mediaSizes.min),
  sm: customMediaQuery(mediaSizes.sm),
  md: customMediaQuery(mediaSizes.md),
  lg: customMediaQuery(mediaSizes.lg),
  xl: customMediaQuery(mediaSizes.xl),
  xxl: customMediaQuery(mediaSizes.xxl),
  max: customMediaQuery(mediaSizes.max),
} as const;
