import styled, { css } from 'styled-components';
import { AppColor } from '../../../theme';

const baseHeadingStyles = css`
  font-style: normal;
  line-height: 1.3;
`;

const boldWeightStyles = css`
  font-weight: 700;
`;

const lightWeightStyles = css`
  font-weight: 400;
`;

const mediumWeightStyles = css`
  font-weight: 500;
`;

const minHeadingStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.titleMin.sm,
      theme.font.sizes.titleMin.lg
    )};
`;
const xsHeadingStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.titleXs.sm,
      theme.font.sizes.titleXs.lg
    )};
`;
const smHeadingStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.titleSm.sm,
      theme.font.sizes.titleSm.lg
    )};
`;
const mdHeadingStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.titleMd.sm,
      theme.font.sizes.titleMd.lg
    )};
`;
const lgHeadingStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.titleLg.sm,
      theme.font.sizes.titleLg.lg
    )};
`;
const xlHeadingStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.titleXl.sm,
      theme.font.sizes.titleXl.lg
    )};
`;

const titleSizeStyles = {
  min: minHeadingStyles,
  xs: xsHeadingStyles,
  sm: smHeadingStyles,
  md: mdHeadingStyles,
  lg: lgHeadingStyles,
  xl: xlHeadingStyles,
} as const;

const titleWeightStyles = {
  light: lightWeightStyles,
  medium: mediumWeightStyles,
  bold: boldWeightStyles,
} as const;

type HeadingSize = keyof typeof titleSizeStyles;
type HeadingWeight = keyof typeof titleWeightStyles;

const Heading = styled.h1<{
  size?: HeadingSize;
  weight?: HeadingWeight;
  color?: AppColor;
}>`
  ${baseHeadingStyles};
  ${({ size }) => titleSizeStyles[size ?? 'md']};
  ${({ weight }) => titleWeightStyles[weight ?? 'medium']};
  color: ${({ color, theme }) => theme.colors[color ?? 'darkestGray']};
`;

export default Heading;
