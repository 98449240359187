import styled from 'styled-components';
import { SocialLink } from './data';

type FooterSocialsProps = {
  socialLinks: SocialLink[];
};
const FooterSocials = ({ socialLinks }: FooterSocialsProps) => (
  <Container>
    {socialLinks.map(({ Icon, url }, index) => (
      <Anchor key={index} href={url}>
        <Icon />
      </Anchor>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'margin-bottom',
      theme.helpers.$rem(16),
      theme.helpers.$rem(32)
    )};

  ${({ theme }) =>
    theme.fluid.screen('gap', theme.helpers.$rem(16), theme.helpers.$rem(32))};
`;

const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    ${({ theme }) =>
      theme.fluid.screen(
        'width',
        theme.helpers.$rem(16),
        theme.helpers.$rem(24)
      )};
  }
`;

export default FooterSocials;
