import { ThingsWeDo } from './data';
import styled from 'styled-components';
import { Paragraph } from '../ui';

type WhatWeDoItemProps = {
  things: ThingsWeDo;
};
const WhatWeDoItem = ({ things }: WhatWeDoItemProps) => {
  const { title, description, Icon } = things;
  return (
    <Container>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Body>
        <Paragraph size="xl" weight="bold" className="title">
          {title}
        </Paragraph>
        <Paragraph size="md" weight="light">
          {description}
        </Paragraph>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 21.2rem;
`;
const IconContainer = styled.div`
  width: 100%;
  margin-inline: auto;
  svg {
    width: 100%;
  }

  ${({ theme }) =>
    theme.fluid.screen(
      'max-width',
      theme.helpers.$rem(120),
      theme.helpers.$rem(184)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'margin-bottom',
      theme.helpers.$rem(12),
      theme.helpers.$rem(18)
    )};
`;

const Body = styled.div`
  p {
    text-align: center;
  }
  .title {
    ${({ theme }) =>
      theme.fluid.screen(
        'margin-bottom',
        theme.helpers.$rem(12),
        theme.helpers.$rem(0)
      )};
  }
`;

export default WhatWeDoItem;
