import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './index';
import GlobalStyles from './globalStyles';

type AppThemeProviderProps = {
  children: ReactNode;
};
const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <GlobalStyles />
    </ThemeProvider>
  );
};

export default AppThemeProvider;
