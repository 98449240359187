import styled, { css } from 'styled-components';
import { AppColor } from '../../../theme';

const baseParagraphStyles = css`
  font-weight: 400;
  line-height: 150%;
`;

const lightWeightStyles = css`
  font-weight: 400;
`;

const mediumWeightStyles = css`
  font-weight: 500;
`;

const semiboldWeightStyles = css`
  font-weight: 600;
`;

const boldWeightStyles = css`
  font-weight: 700;
`;

const smParagraphStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.textSm.sm,
      theme.font.sizes.textSm.lg
    )};
`;
const mdParagraphStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.textMd.sm,
      theme.font.sizes.textMd.lg
    )};
`;
const lgParagraphStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.textLg.sm,
      theme.font.sizes.textLg.lg
    )};
`;
const xlParagraphStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.textXl.sm,
      theme.font.sizes.textXl.lg
    )};
`;
const xXlParagraphStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.textXxl.sm,
      theme.font.sizes.textXxl.lg
    )};
`;

const normalStyleStyles = css`
  font-style: ${({ theme }) => theme.font.styles.normal};
`;

const italicStyleStyles = css`
  font-style: ${({ theme }) => theme.font.styles.italic};
`;

const textSizeStyles = {
  sm: smParagraphStyles,
  md: mdParagraphStyles,
  lg: lgParagraphStyles,
  xl: xlParagraphStyles,
  xxl: xXlParagraphStyles,
} as const;

const textWeightStyles = {
  light: lightWeightStyles,
  medium: mediumWeightStyles,
  semibold: semiboldWeightStyles,
  bold: boldWeightStyles,
} as const;

const textFontStyleStyles = {
  normal: normalStyleStyles,
  italic: italicStyleStyles,
} as const;

type ParagraphSize = keyof typeof textSizeStyles;
type ParagraphWeight = keyof typeof textWeightStyles;
type ParagraphStyle = keyof typeof textFontStyleStyles;

export const AppColorSpan = styled.span<{
  color?: AppColor;
}>`
  color: ${({ color, theme }) => theme.colors[color ?? 'white']};
`;

const Paragraph = styled.p<{
  size?: ParagraphSize;
  weight?: ParagraphWeight;
  textStyle?: ParagraphStyle;
  color?: AppColor;
}>`
  ${baseParagraphStyles};
  ${({ size }) => textSizeStyles[size ?? 'md']};
  ${({ weight }) => textWeightStyles[weight ?? 'medium']};
  ${({ textStyle }) => textFontStyleStyles[textStyle ?? 'normal']};

  color: ${({ color, theme }) => theme.colors[color ?? 'darkestGray']};
`;

export default Paragraph;
