const Star = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_670_15643)">
      <path
        d="M14.43 10L12 2L9.57 10H2L8.18 14.41L5.83 22L12 17.31L18.18 22L15.83 14.41L22 10H14.43Z"
        fill="url(#paint0_linear_670_15643)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_670_15643"
        x1="2"
        y1="2"
        x2="22"
        y2="2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEBEB" />
        <stop offset="1" stopColor="#EBEBEB" />
      </linearGradient>
      <clipPath id="clip0_670_15643">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Star;
