const Facebook = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12.0733C24 5.40541 18.6274 0 12 0C5.37257 0 0 5.40541 0 12.0733C0 18.0995 4.38823 23.0942 10.125 24V15.5632H7.07812V12.0733H10.125V9.41343C10.125 6.38755 11.9165 4.71614 14.6576 4.71614C15.9705 4.71614 17.3437 4.95195 17.3437 4.95195V7.92313H15.8306C14.3399 7.92313 13.8751 8.85379 13.8751 9.80857V12.0733H17.2031L16.6711 15.5632H13.8751V24C19.6118 23.0942 24 18.0995 24 12.0733Z"
      fill="#0B081C"
    />
  </svg>
);

export default Facebook;
