import styled from 'styled-components';
import { Heading, Paragraph } from '../ui';
import FooterSocials from './socials';
import FooterNavigation from './navigation';
import { footerNavigation, socialLinks } from './data';
import { appImages, AppVectors } from '../../assets';
import AppLogo from "../logo";

const FooterSection = () => {
  return (
    <Container>
      <DetailsContainer>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <Paragraph size="md" weight="light">
          We{"'"}re on a mission to help everyone move their finances forward
          and gain financial momentum.
        </Paragraph>
        <FooterSocials socialLinks={socialLinks} />
        <DownloadContainer>
          <DownloadLink href="https://apps.apple.com/us/app/usezeeh/id6458531408">
            <AppVectors.DownloadOptions.AppleStore />
          </DownloadLink>
          <DownloadLink href="https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share">
            <AppVectors.DownloadOptions.PlayStore />
          </DownloadLink>
        </DownloadContainer>
      </DetailsContainer>
      <LinksContainer>
        <FooterNavigation footerNavigation={footerNavigation} />
      </LinksContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-block: 3.75rem;

  ${({ theme }) =>
    theme.fluid.screen(
      'margin-block',
      theme.helpers.$rem(12),
      theme.helpers.$rem(60)
    )};

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) =>
    theme.fluid.between(
      32,
      200,
      theme.helpers.$rem(1000),
      theme.helpers.$rem(1440)
    )};

  ${({ theme }) => theme.helpers.$custom(57)} {
    flex-direction: column;
    gap: ${({ theme }) =>
      theme.fluid.between(
        50,
        50,
        theme.helpers.$rem(375),
        theme.helpers.$rem(912)
      )};
  }
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(16),
      theme.helpers.$rem(100)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(24),
      theme.helpers.$rem(64)
    )};
`;

const DetailsContainer = styled.div`
  max-width: 16.625rem;
  h1 {
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 1.5rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    color: ${({ theme }) => theme.colors.darkestGray};
  }
  margin-bottom: 1rem;

  
`;

const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 1rem;
  a,
  svg {
    width: fit-content;
    ${({ theme }) =>
      theme.fluid.screen(
        'height',
        theme.helpers.$rem(32),
        theme.helpers.$rem(40)
      )};
  }
`;

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const LinksContainer = styled.div``;

export default FooterSection;
