import styled from 'styled-components';
import CatchyPhraseSection from './components/catchy-phrase';
import FeaturesSection from './components/features';
import FooterSection from './components/footer';
import GoalSection from './components/goal';
import HeroSection from './components/hero';
import Navbar from "./components/navbar";
import OfferSection from './components/offers';
import StatisticsSection from './components/statistics';
import TestimonySection from './components/testimony';
import WhatWeDoSection from './components/what-we-do';

function App() {
  return (
    <Container>
      <Navbar />
      <HeroSection />
      <GoalSection />
      <FeaturesSection />
      <WhatWeDoSection />
      <OfferSection />
      <StatisticsSection />
      <TestimonySection />
      <CatchyPhraseSection />
      <FooterSection />
    </Container>
  );
}

const Container = styled.div`
  margin-inline: auto;
`;

export default App;
