const StarLineDouble = () => (
  <svg
    width="419"
    height="24"
    viewBox="0 0 419 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M369 12L41.4607 12"
      stroke="#E3E8EC"
      strokeWidth="1.79"
      strokeMiterlimit="10"
    />
    <path
      d="M24.8764 1.11808L30.2101 9.50487L30.3573 9.73628L30.6285 9.77642L45.6542 12L30.6285 14.2236L30.3573 14.2637L30.2101 14.4951L24.8764 22.8819L19.5427 14.4951L19.3956 14.2637L19.1243 14.2236L4.09861 12L19.1243 9.77642L19.3956 9.73628L19.5427 9.50487L24.8764 1.11808Z"
      stroke="#E3E8EC"
      strokeWidth="1.2"
    />
    <path
      d="M393.876 1.11808L399.21 9.50487L399.357 9.73628L399.629 9.77642L414.654 12L399.629 14.2236L399.357 14.2637L399.21 14.4951L393.876 22.8819L388.543 14.4951L388.396 14.2637L388.124 14.2236L373.099 12L388.124 9.77642L388.396 9.73628L388.543 9.50487L393.876 1.11808Z"
      stroke="#E3E8EC"
      strokeWidth="1.2"
    />
  </svg>
);

export default StarLineDouble;
