import styled from 'styled-components';
import { Heading } from '../ui';
import { textGradients } from '../../theme/components/text-gradient';
import Badge from '../badge';
import StackedCards from './stacked-cards';

const GoalSection = () => {
  return (
    <Container>
      <BadgeContainer>
        <Badge text="Our goal is simple" />
      </BadgeContainer>
      <SmallTitleContainer>
        <Heading size="sm" weight="bold">
          Helping our customers
        </Heading>
      </SmallTitleContainer>
      <BigTitleContainer>
        <Heading size="md" weight="bold">
          Make Money and Spend Wisely
        </Heading>
      </BigTitleContainer>
      <StackedCards />
    </Container>
  );
};

const Container = styled.div`
  padding-block: 4rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(24),
      theme.helpers.$rem(64)
    )};
`;
const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme }) =>
    theme.fluid.screen(
      'margin-bottom',
      theme.helpers.$rem(8),
      theme.helpers.$rem(0)
    )};
`;
const SmallTitleContainer = styled.div`
  h1 {
    text-align: center;
  }
`;
const BigTitleContainer = styled.div`
  margin-bottom: 3rem;
  max-width: 90%;
  margin-inline: auto;
  ${({ theme }) =>
    theme.fluid.screen(
      'margin-bottom',
      theme.helpers.$rem(20),
      theme.helpers.$rem(48)
    )};
  h1 {
    text-align: center;
    ${textGradients.textGradient2};
  }
`;
export default GoalSection;
