import { memo } from 'react';
import _ArrowRight from './arrow-right';
import _Facebook from './facebook';
import _LeftChevron from './left-chevron';
import _LinkedIn from './linkedin';
import _RightChevron from './right-chevron';
import _Star from './star';
import _Twitter from './twitter';
import _Youtube from './youtube';

const ArrowRight = memo(_ArrowRight);
const Facebook = memo(_Facebook);
const LeftChevron = memo(_LeftChevron);
const LinkedIn = memo(_LinkedIn);
const RightChevron = memo(_RightChevron);
const Star = memo(_Star);
const Twitter = memo(_Twitter);
const Youtube = memo(_Youtube);

const AppIcons = {
  ArrowRight,
  Facebook,
  LeftChevron,
  LinkedIn,
  RightChevron,
  Star,
  Twitter,
  Youtube,
} as const;

export default AppIcons;
