import { Rem } from './font';

const isNumber = (n: unknown): n is Number => typeof n === 'number';

type SafeMediaQuery =
  | `@media (max-width: ${number}rem)`
  | `@media (max-width: ${number})`;

export const customMediaQuery = (maxWidth: Rem): SafeMediaQuery =>
  isNumber(maxWidth)
    ? `@media (max-width: ${maxWidth}rem)`
    : `@media (max-width: ${maxWidth})`;
