import styled from 'styled-components';
import { Heading, Paragraph } from '../ui';
import { textGradients } from '../../theme/components/text-gradient';
import StatisticItem from './item';
import { statistics } from './data';

const StatisticsSection = () => {
  return (
    <Container>
      <TitleContainer>
        <Paragraph size="md" weight="light">
          We’ve helped a couple of people reach their finance goal
        </Paragraph>
        <Heading size="md" className="highlighted-text">
          We’re only just getting started on our journey
        </Heading>
      </TitleContainer>
      <StatisticsItemContainer>
        {statistics.map((statistic, index) => (
          <StatisticItem key={index} statistic={statistic} />
        ))}
      </StatisticsItemContainer>
    </Container>
  );
};

const Container = styled.div`
  max-width: 90rem;
  margin-inline: auto;
  padding: 5.5rem 1rem;

  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(40),
      theme.helpers.$rem(88)
    )};
`;
const TitleContainer = styled.div`
  max-width: 42.75rem;
  margin-inline: auto;
  margin-bottom: 2.5rem;

  h1,
  p {
    text-align: center;
  }

  p {
    ${({ theme }) =>
      theme.fluid.screen(
        'margin-bottom',
        theme.helpers.$rem(8),
        theme.helpers.$rem(0)
      )};

    ${({ theme }) =>
      theme.fluid.screen(
        'font-size',
        theme.helpers.$rem(13),
        theme.helpers.$rem(16)
      )};
    max-width: 80%;
    margin-inline: auto;
  }
  .highlighted-text {
    ${textGradients.textGradient2};
  }
`;

const StatisticsItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4.5rem;
  flex-wrap: wrap;
`;
export default StatisticsSection;
