const Security = () => (
  <svg
    width="186"
    height="148"
    viewBox="0 0 186 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.19377 90.1252C9.19377 90.1252 9.19377 90.1605 9.19377 90.2311C9.19377 90.3017 9.20731 90.4102 9.21544 90.5432C9.2344 90.8147 9.26421 91.2272 9.29672 91.7429C9.36715 92.7879 9.45656 94.2971 9.56763 96.1618C9.77353 99.8939 10.0173 105.054 10.2639 110.751C10.5104 116.448 10.7434 121.608 10.9276 125.343C11.0224 127.205 11.0983 128.714 11.1525 129.764C11.1796 130.283 11.1985 130.685 11.2121 130.967C11.2121 131.1 11.2121 131.203 11.2256 131.279C11.2274 131.314 11.2274 131.35 11.2256 131.385C11.2256 131.385 11.2256 131.35 11.2121 131.279C11.1985 131.208 11.2121 131.1 11.1904 130.967C11.1714 130.695 11.1416 130.283 11.1091 129.767C11.0387 128.725 10.9493 127.213 10.8382 125.348C10.6323 121.616 10.3885 116.456 10.142 110.756C9.89544 105.056 9.66245 99.8993 9.47823 96.1672C9.38341 94.3052 9.30755 92.7961 9.25337 91.7457C9.22628 91.2272 9.20731 90.8255 9.19377 90.5432C9.19377 90.4102 9.19377 90.3071 9.19377 90.2311C9.19196 90.1958 9.19196 90.1605 9.19377 90.1252Z"
      fill="#E0E0E0"
    />
    <path
      d="M5.65832 100.323C5.72841 100.365 5.79224 100.417 5.84796 100.477C5.96445 100.583 6.11887 100.749 6.32748 100.939C6.72572 101.335 7.26213 101.897 7.84189 102.527L9.31838 104.155L9.7681 104.644C9.82706 104.7 9.87974 104.763 9.92523 104.831C9.85572 104.788 9.79199 104.736 9.73559 104.676C9.61909 104.571 9.46467 104.405 9.25878 104.212C8.86324 103.813 8.33496 103.246 7.7552 102.616L6.26788 101.007L5.81545 100.521C5.75586 100.461 5.70316 100.394 5.65832 100.323Z"
      fill="#E0E0E0"
    />
    <path
      d="M10.226 104.725C10.2016 104.701 11.3096 103.542 12.6913 102.136C14.0729 100.73 15.2189 99.6063 15.2406 99.628C15.2623 99.6497 14.1569 100.811 12.7726 102.217C11.3882 103.623 10.2476 104.744 10.226 104.725Z"
      fill="#E0E0E0"
    />
    <path
      d="M10.2124 113.49C10.2768 113.436 10.3478 113.392 10.4237 113.357L11.0143 113.023C11.5128 112.751 12.1982 112.352 12.9405 111.896C13.6828 111.44 14.3438 111.003 14.8207 110.686L15.3842 110.309C15.4507 110.258 15.5224 110.216 15.5982 110.181C15.5982 110.181 15.5359 110.246 15.4113 110.347C15.2866 110.447 15.0997 110.585 14.8694 110.754C14.4062 111.09 13.7505 111.538 13.0055 111.994C12.2605 112.45 11.5643 112.833 11.0549 113.096C10.8003 113.226 10.5917 113.33 10.4454 113.395C10.3722 113.436 10.2938 113.468 10.2124 113.49Z"
      fill="#E0E0E0"
    />
    <path
      d="M5.2086 110.425C5.28276 110.447 5.35313 110.48 5.41721 110.523C5.54725 110.591 5.73689 110.697 5.95904 110.827C6.41959 111.098 7.0427 111.457 7.74437 111.869C8.44604 112.282 9.06102 112.665 9.51073 112.955C9.73559 113.093 9.91711 113.207 10.0526 113.292C10.1203 113.329 10.1831 113.374 10.2395 113.427C10.1656 113.405 10.0953 113.372 10.0309 113.33L9.48906 113.026C9.02851 112.754 8.4054 112.393 7.70373 111.981C7.00206 111.568 6.38708 111.188 5.93736 110.908C5.7125 110.767 5.53099 110.653 5.39553 110.572C5.3278 110.53 5.26508 110.481 5.2086 110.425Z"
      fill="#E0E0E0"
    />
    <path
      d="M4.21704 118.161C4.31177 118.193 4.40263 118.236 4.48796 118.288L5.18963 118.679C5.78022 119.01 6.59297 119.477 7.48699 119.998C8.38101 120.52 9.18833 120.995 9.76809 121.356L10.4535 121.774C10.5391 121.822 10.6199 121.878 10.6946 121.942C10.5999 121.909 10.509 121.867 10.4237 121.814L9.72203 121.426C9.13144 121.092 8.3187 120.628 7.42468 120.107C6.53066 119.586 5.72333 119.108 5.14357 118.75L4.44732 118.329C4.36475 118.282 4.28755 118.225 4.21704 118.161Z"
      fill="#E0E0E0"
    />
    <path
      d="M10.5592 121.801C10.6436 121.733 10.7343 121.674 10.8301 121.624L11.5643 121.176C12.1847 120.799 13.0381 120.273 13.9646 119.667C14.8911 119.062 15.7201 118.495 16.3134 118.08L17.0205 117.588C17.1051 117.523 17.1959 117.465 17.2914 117.417C17.2166 117.492 17.136 117.561 17.0503 117.624C16.8905 117.751 16.6575 117.928 16.3649 118.145C15.7824 118.579 14.9616 119.16 14.0323 119.773C13.1031 120.387 12.2416 120.894 11.6103 121.255C11.2934 121.434 11.036 121.578 10.8572 121.67C10.7625 121.724 10.6627 121.768 10.5592 121.801Z"
      fill="#E0E0E0"
    />
    <path
      d="M163.204 78.9207C157.845 76.5295 154.364 72.6779 152.115 69.03C149.498 64.7487 148.124 59.8218 148.146 54.8017V48.4639C148.146 47.0281 149.01 45.6818 150.728 44.8784L163.057 40.2641V36L147.81 41.4584C146.212 42.1587 144.161 42.9295 144.161 44.7454V53.7812C144.134 60.1266 145.871 66.3542 149.178 71.7659C152.48 77.2371 157.374 81.5641 163.204 84.1647V78.9207Z"
      fill="#EBEBEB"
    />
    <path
      d="M163.204 84.1647C164.247 83.8227 165.258 83.3885 166.224 82.8673C168.597 81.5183 170.753 79.8191 172.621 77.8269C174.315 75.9978 175.806 73.9902 177.069 71.8392C180.423 66.3917 182.118 60.0819 181.945 53.6835V46.2219C181.945 42.5061 180.049 42.232 178.424 41.5317L163.044 36V40.2614L175.506 44.9544C177.223 45.7687 178.088 47.1014 178.088 48.5372V54.875C178.109 59.8954 176.734 64.8224 174.116 69.1032C171.873 72.7594 168.551 76.5295 163.204 78.9207V84.1647Z"
      fill="#E0E0E0"
    />
    <path
      d="M167.124 54.3946L167.14 53.1759C167.14 52.1148 166.719 51.0972 165.97 50.3469C165.221 49.5966 164.206 49.175 163.147 49.175C162.088 49.175 161.072 49.5966 160.323 50.3469C159.574 51.0972 159.153 52.1148 159.153 53.1759L159.137 54.4163C158.609 54.4973 158.128 54.765 157.78 55.1707C157.432 55.5765 157.241 56.0936 157.241 56.6284V63.8213C157.241 64.4151 157.476 64.9847 157.895 65.4047C158.314 65.8246 158.883 66.0605 159.476 66.0605H166.931C167.524 66.0605 168.093 65.8246 168.512 65.4047C168.931 64.9847 169.166 64.4151 169.166 63.8213V56.6284C169.167 56.0674 168.958 55.5265 168.579 55.1129C168.201 54.6992 167.682 54.4429 167.124 54.3946ZM164.211 62.0271H162.263L162.567 60.6157C162.35 60.4799 162.182 60.2774 162.089 60.0384C161.996 59.7993 161.983 59.5366 162.051 59.2894C162.12 59.0422 162.266 58.8238 162.469 58.6668C162.671 58.5098 162.919 58.4226 163.175 58.4183C163.431 58.414 163.682 58.4928 163.889 58.6429C164.097 58.793 164.25 59.0063 164.327 59.2511C164.404 59.4958 164.399 59.7588 164.315 60.0009C164.23 60.2429 164.069 60.451 163.856 60.594L164.211 62.0271ZM166.262 54.3837H159.999L160.015 53.1759C160.039 52.3593 160.379 51.5843 160.964 51.0152C161.549 50.4461 162.333 50.1278 163.148 50.1278C163.963 50.1278 164.747 50.4461 165.332 51.0152C165.917 51.5843 166.257 52.3593 166.281 53.1759L166.262 54.3837Z"
      fill="#E0E0E0"
    />
    <path
      d="M150.628 54.3078C150.498 53.9022 150.421 53.4817 150.397 53.0565C150.283 52.0375 150.241 51.0116 150.273 49.9867C150.289 49.3868 150.33 48.8168 150.384 48.2984C150.408 47.8277 150.525 47.3665 150.728 46.9412C150.877 46.6511 151.111 46.4141 151.4 46.2627C151.608 46.165 151.738 46.1704 151.741 46.184C151.37 46.3571 151.071 46.6548 150.896 47.0254C150.729 47.4401 150.637 47.8813 150.625 48.3282C150.584 48.8412 150.552 49.4139 150.536 50.0002C150.5 51.1891 150.536 52.2666 150.582 53.0456C150.628 53.8246 150.668 54.3023 150.628 54.3078Z"
      fill="white"
    />
    <path
      d="M150.856 58.2408C150.78 58.2408 150.712 57.8716 150.704 57.4102C150.695 56.9488 150.747 56.5769 150.82 56.5769C150.894 56.5769 150.964 56.946 150.972 57.3912C150.98 57.8363 150.929 58.238 150.856 58.2408Z"
      fill="white"
    />
    <path
      d="M9.01498 85.5707C9.01498 85.5707 6.75284 85.4052 6.96144 90.4917C7.17005 95.5783 7.69833 98.2871 7.69833 98.2871C7.69833 98.2871 7.63061 99.4135 6.76097 99.1937C5.89133 98.9738 4.24688 97.8745 3.43955 98.6942C2.63222 99.5139 2.89772 100.849 4.01118 102.388C5.12464 103.927 7.42742 107.383 7.64144 107.695C7.85547 108.007 7.9259 108.58 7.30822 108.525C6.69053 108.471 2.52115 107.051 1.96848 109.068C1.41581 111.085 5.21946 114.86 5.63125 115.281C6.04304 115.702 6.0593 115.968 5.90217 116.22C5.74504 116.473 4.37963 115.867 3.17676 115.512C1.9739 115.156 0.519086 114.969 0.296935 116.152C0.0747851 117.336 1.7382 120.851 8.02343 124.523L11.0414 126.032L13.8996 124.206C19.7811 119.923 21.1005 116.277 20.7619 115.113C20.4232 113.948 18.9928 114.299 17.8306 114.763C16.6683 115.227 15.3788 115.979 15.1891 115.743C14.9995 115.506 14.9941 115.24 15.3598 114.779C15.7255 114.318 19.1337 110.186 18.3832 108.229C17.6328 106.272 13.6232 108.099 13.0137 108.216C12.4041 108.333 12.4285 107.752 12.6019 107.426C12.7753 107.1 14.7367 103.42 15.693 101.78C16.6494 100.141 16.8146 98.8109 15.8935 98.0482C14.9724 97.2855 13.4851 98.5694 12.6425 98.8761C11.8 99.1828 11.6185 98.0618 11.6185 98.0618C11.6185 98.0618 11.8623 95.315 11.5616 90.2339C11.2609 85.1527 8.97163 85.5626 8.97163 85.5626"
      fill="#EBEBEB"
    />
    <path
      d="M15.0998 127.023H6.74475L9.47016 147.706H12.3717L15.0998 127.023Z"
      fill="#E0E0E0"
    />
    <path
      d="M7.17822 129.566C7.17822 129.566 7.17822 129.593 7.22157 129.64C7.27724 129.703 7.34681 129.752 7.42476 129.783C7.57009 129.834 7.72494 129.85 7.87752 129.831C8.0301 129.812 8.17638 129.759 8.30523 129.675C8.64387 129.488 8.93646 129.132 9.38889 128.961C9.8908 128.799 10.4324 128.809 10.9277 128.991C11.4314 129.167 11.9118 129.404 12.3581 129.697C12.7312 129.93 13.1195 130.139 13.5203 130.321C13.7937 130.444 14.0827 130.528 14.3791 130.571C14.4591 130.581 14.5396 130.587 14.6203 130.587C14.6772 130.587 14.707 130.587 14.707 130.587C14.3107 130.526 13.9249 130.41 13.561 130.242C13.1692 130.05 12.7892 129.834 12.4231 129.596C11.9706 129.295 11.4806 129.054 10.9656 128.88C10.4415 128.689 9.86848 128.682 9.34012 128.861C9.11667 128.951 8.91047 129.08 8.73056 129.241C8.57903 129.374 8.41884 129.497 8.25105 129.61C8.13391 129.691 8.00065 129.746 7.86037 129.771C7.72009 129.796 7.57612 129.79 7.4383 129.754C7.34127 129.707 7.25322 129.644 7.17822 129.566Z"
      fill="#F5F5F5"
    />
    <path
      d="M7.48169 133.374C7.48169 133.374 7.54942 133.29 7.67946 133.152C7.86879 132.965 8.09606 132.82 8.34591 132.728C8.71803 132.594 9.11293 132.534 9.50813 132.552C9.99652 132.59 10.4823 132.656 10.9629 132.75C11.4526 132.847 11.9542 132.868 12.4503 132.813C12.8558 132.761 13.2513 132.649 13.6233 132.479C13.8731 132.365 14.1093 132.224 14.3277 132.058C14.412 132.003 14.4871 131.936 14.5499 131.857C14.2421 132.062 13.9197 132.243 13.5854 132.4C13.2185 132.553 12.8317 132.654 12.4367 132.699C11.9518 132.745 11.4627 132.722 10.9846 132.628C10.4992 132.531 10.0077 132.467 9.51355 132.438C9.10219 132.423 8.69229 132.495 8.31069 132.65C8.05255 132.753 7.82301 132.917 7.64153 133.127C7.59284 133.184 7.55017 133.246 7.5142 133.312C7.50027 133.331 7.48931 133.352 7.48169 133.374Z"
      fill="#F5F5F5"
    />
    <path
      d="M14.1542 135.413C14.0843 135.361 14.0066 135.321 13.924 135.293C13.7012 135.21 13.4676 135.16 13.2304 135.144C12.8807 135.124 12.5299 135.144 12.1847 135.204C11.7946 135.269 11.3719 135.375 10.9385 135.475C10.5301 135.571 10.1118 135.619 9.69226 135.616C9.35305 135.607 9.01853 135.535 8.70613 135.402C8.42458 135.266 8.17378 135.074 7.96924 134.837C8.00067 134.919 8.04865 134.993 8.11011 135.055C8.26789 135.236 8.46166 135.382 8.67904 135.483C8.99949 135.634 9.34663 135.72 9.70039 135.736C10.1306 135.747 10.5604 135.703 10.9791 135.603C11.4234 135.508 11.8379 135.397 12.2172 135.331C12.5535 135.266 12.896 135.237 13.2385 135.245C13.5494 135.264 13.8568 135.32 14.1542 135.413Z"
      fill="#F5F5F5"
    />
    <path
      d="M8.22116 138.282C8.47694 138.17 8.76115 138.142 9.03391 138.2C9.32861 138.273 9.61401 138.38 9.88458 138.518C10.2305 138.683 10.6028 138.786 10.9845 138.822C11.6832 138.883 12.3833 138.721 12.9838 138.358C13.1632 138.248 13.3303 138.12 13.4823 137.975C13.5826 137.866 13.634 137.804 13.6286 137.796C13.407 137.969 13.1754 138.128 12.9351 138.274C12.3439 138.599 11.6702 138.742 10.998 138.686C10.2043 138.654 9.5947 138.168 9.05287 138.111C8.84223 138.074 8.62553 138.092 8.42435 138.165C8.27535 138.214 8.21574 138.276 8.22116 138.282Z"
      fill="#F5F5F5"
    />
    <path
      d="M8.68715 142.253C8.92679 142.258 9.16455 142.209 9.38341 142.112C9.80332 141.973 10.3235 141.65 10.9303 141.409C11.2129 141.293 11.5139 141.23 11.8189 141.221C12.0655 141.222 12.3088 141.278 12.5314 141.384C12.7344 141.494 12.911 141.647 13.0489 141.832C13.0374 141.767 13.0094 141.706 12.9676 141.656C12.8666 141.508 12.732 141.386 12.5748 141.3C12.3423 141.171 12.0819 141.101 11.8162 141.096C11.4947 141.096 11.1763 141.159 10.8789 141.281C10.2503 141.531 9.74643 141.865 9.34548 142.022C8.94452 142.179 8.68445 142.231 8.68715 142.253Z"
      fill="#F5F5F5"
    />
    <path
      d="M9.09619 144.099C9.26736 144.182 9.44996 144.24 9.63802 144.27C10.0728 144.376 10.5146 144.451 10.9601 144.495C11.4101 144.54 11.8628 144.553 12.3147 144.533C12.4974 144.536 12.6796 144.514 12.8565 144.468C12.8565 144.427 12.0112 144.468 10.9763 144.359C9.94145 144.251 9.10703 144.058 9.09619 144.099Z"
      fill="#F5F5F5"
    />
    <path
      d="M185.316 147.722C185.316 147.763 143.961 147.793 92.9608 147.793C41.9611 147.793 0.600464 147.763 0.600464 147.722C0.600464 147.681 41.9448 147.651 92.9608 147.651C143.977 147.651 185.316 147.684 185.316 147.722Z"
      fill="#263238"
    />
    <g opacity="0.8">
      <path
        d="M123.672 16.6795L123.701 16.6904L123.731 16.6993C126.574 17.5511 128.8 18.8097 130.325 20.648C131.837 22.4729 132.743 24.9715 132.758 28.4735L132.758 51.6104L132.758 51.6153C132.846 69.4364 128.083 86.9442 118.982 102.256C111.205 115.233 98.6043 130.221 81.8627 135.966C63.4746 127.459 51.5264 114.18 43.7781 101.257C34.6771 85.9431 29.9141 68.4331 30.0012 50.6099V50.605V24.8783C30.0012 22.6949 30.962 21.2851 32.6322 20.0576C33.9874 19.0616 35.7134 18.2508 37.7089 17.3133C38.2893 17.0406 38.8924 16.7573 39.5159 16.4555L81.3666 1.0657L123.672 16.6795Z"
        fill="white"
        stroke="#4F54E3"
        strokeWidth="2"
      />
    </g>
    <g opacity="0.4">
      <path
        d="M102.411 72.1819H96.6964V41.0358C96.6964 37.5087 95.298 34.126 92.8086 31.632C90.3193 29.138 86.943 27.7369 83.4226 27.7369C79.9022 27.7369 76.5259 29.138 74.0366 31.632C71.5473 34.126 70.1488 37.5087 70.1488 41.0358V72.1819H64.4342V41.0358C64.3951 38.5126 64.8574 36.0069 65.7941 33.6645C66.7308 31.322 68.1233 29.1897 69.8904 27.3915C71.6575 25.5934 73.764 24.1654 76.0872 23.1907C78.4104 22.216 80.9039 21.714 83.4226 21.714C85.9413 21.714 88.4348 22.216 90.758 23.1907C93.0812 24.1654 95.1877 25.5934 96.9548 27.3915C98.7219 29.1897 100.114 31.322 101.051 33.6645C101.988 36.0069 102.45 38.5126 102.411 41.0358V72.1819Z"
        fill="black"
      />
    </g>
    <path
      d="M102.411 72.1819H96.6964V41.0358C96.6964 37.5087 95.298 34.126 92.8086 31.632C90.3193 29.138 86.943 27.7369 83.4226 27.7369C79.9022 27.7369 76.5259 29.138 74.0366 31.632C71.5473 34.126 70.1488 37.5087 70.1488 41.0358V72.1819H64.4342V41.0358C64.3951 38.5126 64.8574 36.0069 65.7941 33.6645C66.7308 31.322 68.1233 29.1897 69.8904 27.3915C71.6575 25.5934 73.764 24.1654 76.0872 23.1907C78.4104 22.216 80.9039 21.714 83.4226 21.714C85.9413 21.714 88.4348 22.216 90.758 23.1907C93.0812 24.1654 95.1877 25.5934 96.9548 27.3915C98.7219 29.1897 100.114 31.322 101.051 33.6645C101.988 36.0069 102.45 38.5126 102.411 41.0358V72.1819Z"
      fill="#592BCC"
    />
    <path
      d="M106.356 58.322H60.4888C59.0219 58.322 57.8327 59.5135 57.8327 60.9832V88.5566C57.8327 90.0263 59.0219 91.2178 60.4888 91.2178H106.356C107.823 91.2178 109.013 90.0263 109.013 88.5566V60.9832C109.013 59.5135 107.823 58.322 106.356 58.322Z"
      fill="#360B81"
    />
    <path
      d="M87.6997 70.4872C87.7002 69.6894 87.4785 68.9074 87.0596 68.229C86.6407 67.5506 86.0412 67.0027 85.3285 66.647C84.6159 66.2913 83.8182 66.1419 83.0254 66.2156C82.2326 66.2893 81.476 66.5831 80.8408 67.064C80.2055 67.545 79.7168 68.1939 79.4296 68.938C79.1424 69.682 79.0681 70.4915 79.215 71.2756C79.3619 72.0596 79.7243 72.787 80.2613 73.376C80.7983 73.9649 81.4887 74.3921 82.2548 74.6094V81.0219H84.5909V74.6094C85.4872 74.3565 86.2762 73.8166 86.8375 73.0723C87.3988 72.328 87.7016 71.4201 87.6997 70.4872Z"
      fill="#DDD2FC"
    />
    <path
      d="M60.5625 88.0985C60.4434 88.0985 60.3518 82.6021 60.3518 75.8462C60.3518 69.0902 60.4434 63.5939 60.5625 63.5939C60.6816 63.5939 60.7777 69.0902 60.7777 75.8462C60.7777 82.6021 60.6816 88.0985 60.5625 88.0985Z"
      fill="#FAFAFA"
    />
    <path
      d="M80.074 75.9791C80.074 75.9791 79.8728 75.915 79.5711 75.6951C79.1482 75.3785 78.7766 74.9985 78.4693 74.5684C77.9357 73.8628 77.5644 73.0478 77.382 72.1817C77.1996 71.3155 77.2106 70.4197 77.4142 69.5583C77.6178 68.6969 78.0089 67.8913 78.5596 67.1991C79.1103 66.5069 79.807 65.9452 80.5997 65.5543C81.0739 65.3091 81.5827 65.1377 82.1084 65.0459C82.2959 64.9944 82.4923 64.9835 82.6844 65.0138C82.6844 65.0963 81.8752 65.1742 80.7643 65.82C80.0392 66.2217 79.4059 66.7708 78.9052 67.4322C78.4044 68.0935 78.0472 68.8525 77.8565 69.6603C77.6658 70.4682 77.6458 71.3071 77.7978 72.1231C77.9498 72.9391 78.2704 73.7143 78.7391 74.3989C79.1403 74.9612 79.5868 75.4897 80.074 75.9791Z"
      fill="#FAFAFA"
    />
  </svg>
);
export default Security;
