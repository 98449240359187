import styled from 'styled-components';
import Feature from './item';
import { features } from './data';

const FeaturesSection = () => {
  return (
    <Container>
      {features.map((feature, index) => (
        <Feature key={index} feature={feature} reverse={index % 2 !== 0} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 4rem 6.25rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(16),
      theme.helpers.$rem(100)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(16),
      theme.helpers.$rem(64)
    )};
  
  max-width: 90rem;
  margin-inline: auto;
`;

export default FeaturesSection;
