import { appHelpers } from './helpers';
import { appColors } from './utils/colors';
import { appFluid } from './utils/fluid';
import { appFont } from './utils/font';
import { appGradients } from './utils/gradients';
import { appMedia } from './utils/media';
import { appRadius } from './utils/radius';
import { appStack } from './utils/stack';
import AppThemeProvider from './provider';

export type AppColor = keyof typeof appColors;

const theme = {
  colors: appColors,
  fluid: appFluid,
  font: appFont,
  gradients: appGradients,
  media: appMedia,
  radius: appRadius,
  stack: appStack,
  helpers: appHelpers,
} as const;

export default theme;

export { AppThemeProvider };

type ThemeType = typeof theme;
declare module 'styled-components' {
  export interface DefaultTheme extends ThemeType {}
}
