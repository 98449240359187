import styled from 'styled-components';
import { FooterNavigationItem } from './data';
import { Paragraph } from '../ui';

type FooterNavigationProps = {
  footerNavigation: FooterNavigationItem[];
};
const FooterNavigation = ({ footerNavigation }: FooterNavigationProps) => {
  return (
    <Container>
      {footerNavigation.map(({ title, links }, index) => (
        <Item key={index}>
          <Paragraph size="sm" weight="light" className="title">
            {title}
          </Paragraph>
          {links.map(({ label, url }, _index) => (
            <Anchor key={_index} href={url}>
              <Paragraph
                color="primary"
                size="sm"
                weight="light"
                className="title"
              >
                {label}
              </Paragraph>
            </Anchor>
          ))}
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) =>
    theme.fluid.between(
      60,
      128,
      theme.helpers.$rem(1000),
      theme.helpers.$rem(1440)
    )};
  padding-right: ${({ theme }) =>
    theme.fluid.between(
      40,
      100,
      theme.helpers.$rem(1000),
      theme.helpers.$rem(1440)
    )};
  flex-wrap: wrap;

  ${({ theme }) => theme.helpers.$custom(57)} {
    width: 100%;
    gap: ${({ theme }) =>
      theme.fluid.between(
        50,
        128,
        theme.helpers.$rem(375),
        theme.helpers.$rem(912)
      )};
  }
`;

const Item = styled.div`
  .title {
    margin-bottom: 1.5rem;
  }
`;

const Anchor = styled.a`
  display: block;
  p:hover {
    color: ${({ theme }) => theme.colors.darkPurple};
  }
`;
export default FooterNavigation;
