import styled, { css } from 'styled-components';
import type { VariantButtonProps } from './types';

const BaseButton = ({
  children,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  buttonRef,
  loading,
  disabled,
  small,
  as,
  showLoadingSpinner,
  alt,
  rounded,
  ...otherProps
}: VariantButtonProps) => {
  return (
    <ButtonContainer
      disabled={disabled || loading}
      ref={buttonRef}
      small={small}
      rounded={rounded}
      {...otherProps}
    >
      <>
        {LeftIcon && <LeftIcon />}
        {children}
        {RightIcon && <RightIcon />}
      </>
    </ButtonContainer>
  );
};

const smallButtonStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(24),
      theme.helpers.$rem(24)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(6),
      theme.helpers.$rem(6)
    )};
`;
const bigButtonStyles = css`
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(18.5),
      theme.helpers.$rem(38.5)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(5.5),
      theme.helpers.$rem(12.5)
    )};
`;
const roundedButtonStyles = css`
  border-radius: 2.75rem;
`;

const disabledButtonStyles = css`
  cursor: default
`;

const ButtonContainer = styled.button<{
  small?: boolean;
  wide?: boolean;
  rounded?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  outline: none transparent;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radius.min};
  ${({ small }) => (small ? smallButtonStyles : bigButtonStyles)};
  ${({ rounded }) => rounded && roundedButtonStyles};
  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.font.sizes.textLg.sm,
      theme.font.sizes.textLg.lg
    )};
  font-weight: 500;
  line-height: 1.5;

  user-select: none;
  cursor: pointer;
  ${({ disabled }) => disabled && disabledButtonStyles};
  transition: all 0.3s ease;

  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.helpers.$rem(14),
      theme.helpers.$rem(16)
    )};
  svg {
    max-width: 1.125rem;
    transition: all 0.3s ease;
  }
  
  &:hover {
    svg {
      margin-left: 2px
    }
  }
`;

export default BaseButton;
