import heroPhoneLight from './hero-phone-light.png';
import heroPhoneDark from './hero-phone-dark.png';

import phoneOne from './phone-one.png';
import phoneTwo from './phone-two.png';
import phoneThree from './phone-three.png';

import logo from './logo.png';

import transparentBackground from './transparent-background.png';

const appImages = {
  heroPhoneLight,
  heroPhoneDark,
  logo,
  phoneOne,
  phoneTwo,
  phoneThree,
  transparentBackground
} as const;

export default appImages;
