import styled, { css } from 'styled-components';
import { AppColor } from '../../../theme';
import type { VariantButtonProps } from './types';
import BaseButton from './base';

const FilledButton = ({
  alt,
  layered,
  wide,
  disabled,
  ...otherProps
}: VariantButtonProps) => {
  return (
    <FilledButtonWrapper
      isAlt={alt}
      isLayered={layered}
      wide={wide}
      disabled={disabled}
    >
      <BaseButton disabled={disabled} {...otherProps} />
    </FilledButtonWrapper>
  );
};

const altStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
`;

const normalStyles = css`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  
  &:hover {
  background-color: ${({ theme }) => theme.colors.darkPurple};
  }
`;

const layeredStyles = (color: AppColor) => css`
  &::before {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: -5px;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors[color]};
    border-radius: ${({ theme }) => theme.radius.lg};
  }
`;

const wideButtonContainer = css`
  width: 100%;
  button {
    width: 100%;
  }
`;


const FilledButtonWrapper = styled.div<{
  isAlt?: boolean;
  isLayered?: boolean;
  wide?: boolean;
  disabled?: boolean;
}>`
  width: fit-content;
  ${({ wide }) => wide && wideButtonContainer};

  position: relative;
  ${({ isLayered, isAlt }) => isLayered && !isAlt && 'margin: 8px;'};

  button {
    ${({ isAlt }) => (isAlt ? altStyles : normalStyles)};
    ${({ isLayered, isAlt }) =>
      isLayered && !isAlt && layeredStyles('primary')};
  }
`;

export default FilledButton;
