import styled from 'styled-components';

type BadgeProps = {
  text: string;
};
const Badge = ({ text }: BadgeProps) => {
  return <Container>{text}</Container>;
};

const Container = styled.div`
  padding: 0.625rem 1rem;
  text-align: center;
  width: fit-content;
  border-radius: 7rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.darkGray2};
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) =>
    theme.fluid.screen(
      'font-size',
      theme.helpers.$rem(10),
      theme.helpers.$rem(12)
    )};

  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(8),
      theme.helpers.$rem(10)
    )};

  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(12),
      theme.helpers.$rem(16)
    )};
`;

export default Badge;
