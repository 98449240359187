import { fluidRange, between as _between } from 'polished';
import { rem, Rem } from '../helpers/font';

import { mediaSizes } from './media';

export const appFluid = (() => {
  const custom = (
    property: string,
    from: Rem,
    to: Rem,
    minScreen: Rem,
    maxScreen: Rem
  ) =>
    fluidRange(
      { prop: property, fromSize: from, toSize: to },
      minScreen as string,
      maxScreen as string
    );

  const screen = (property: string, from: Rem, to: Rem) =>
    fluidRange(
      { prop: property, fromSize: from, toSize: to },
      mediaSizes.min as string,
      mediaSizes.max as string
    );

  const between = (from: number, to: number, minScreen: Rem, maxScreen: Rem) =>
    _between(rem(from), rem(to), minScreen as string, maxScreen as string);

  return {
    between,
    custom,
    screen,
  };
})();
