import styled from 'styled-components';
import { appImages, AppVectors } from '../../assets';
import NavigationItem from './item';
import { navigationLinks } from './data';
import { Button, Heading, Paragraph } from '../ui';
import AppLogo from "../logo";

const Navbar = () => {
  return (
    <Container>
      <AppLogo />
      <NavigationItem navigationLinks={navigationLinks}></NavigationItem>
      <AuthContainer>
        <a href="https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share">
          <Button variant="stripped">Sign up</Button>
        </a>
        <a href="https://apps.apple.com/us/app/usezeeh/id6458531408">
          <Button variant="filled">Login</Button>
        </a>
      </AuthContainer>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.darkestGray};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(16),
      theme.helpers.$rem(100)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(16),
      theme.helpers.$rem(18)
    )}
`;

const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) =>
    theme.fluid.screen('gap', theme.helpers.$rem(14), theme.helpers.$rem(40))};
`;
export default Navbar;
