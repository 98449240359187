import styled from 'styled-components';
import Badge from '../badge';
import { Paragraph } from '../ui';

const StackedCards = () => {
  return (
    <Container>
      <DetailsContainer>
        <Avatar>
          <Paragraph size="xl" weight="medium">
            F
          </Paragraph>
        </Avatar>
        <TextContainer>
          <Paragraph size="lg" weight="semibold" color="white">
            Fairmoney
          </Paragraph>
          <PriceContainer>
            <Paragraph size="lg" weight="medium" color="white">
              ₦50,000 - ₦100,000
            </Paragraph>
          </PriceContainer>
        </TextContainer>
      </DetailsContainer>
      <BadgeContainer>
        <Badge text="Eligible" />
      </BadgeContainer>
    </Container>
  );
};

const Container = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  max-width: 34.5rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'max-width',
      theme.helpers.$rem(280),
      theme.helpers.$rem(552)
    )};

  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-inline: auto;
  padding: 1.875rem 1.375rem;

  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(16),
      theme.helpers.$rem(30)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(16),
      theme.helpers.$rem(22)
    )};
  position: relative;

  &:before {
    z-index: -1;

    max-width: 89.5%;
    background: ${({ theme }) => theme.colors.darkPurple};
    content: '';
    position: absolute;
    width: 100%;
    left: 5.25%;
    ${({ theme }) =>
      theme.fluid.screen(
        'bottom',
        theme.helpers.$rem(-8),
        theme.helpers.$rem(-16)
      )};
    height: 1rem;
    margin-inline: auto;
  }
  &:after {
    z-index: -2;

    max-width: 78.5%;
    background: ${({ theme }) => theme.colors.darkPurple2};
    content: '';
    position: absolute;
    width: 100%;
    ${({ theme }) =>
      theme.fluid.screen(
        'bottom',
        theme.helpers.$rem(-16),
        theme.helpers.$rem(-32)
      )};
    left: 10.75%;
    height: 1rem;
    margin-inline: auto;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75rem;
  flex: 1;
`;

const Avatar = styled.div`
  max-width: 3.125rem;
  aspect-ratio: 1/1;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  ${({ theme }) =>
    theme.fluid.screen(
      'max-width',
      theme.helpers.$rem(32),
      theme.helpers.$rem(50)
    )};

  p {
    ${({ theme }) =>
      theme.fluid.screen(
        'font-size',
        theme.helpers.$rem(14),
        theme.helpers.$rem(20)
      )};
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
  p {
    ${({ theme }) =>
      theme.fluid.screen(
        'font-size',
        theme.helpers.$rem(12),
        theme.helpers.$rem(18)
      )};
  }
`;
const PriceContainer = styled.div`
  opacity: 0.5;
`;

const BadgeContainer = styled.div`
  max-width: 6.75rem;
  width: 100%;
  div {
    width: 100%;
  }
`;

export default StackedCards;
