import { AppVectors } from '../../assets';

export const offers = [
  {
    title: 'Credit cards',
    Icon: AppVectors.Card,
  },
  {
    title: 'Loan',
    Icon: AppVectors.Loan,
  },
  {
    title: 'Car loan',
    Icon: AppVectors.Key,
  },
  {
    title: 'Mortgage',
    Icon: AppVectors.Mortgage,
  },
];

export type Offer = (typeof offers)[number];
