import styled from 'styled-components';
import { Button, Heading, Paragraph } from '../ui';
import { textGradients } from '../../theme/components/text-gradient';
import { AppIcons, appImages, AppVectors } from '../../assets';
import { FormEventHandler, useState } from 'react';
import toast from 'react-hot-toast';
import Input from '../ui/input';
import axios, { AxiosError } from "axios";

const CatchyPhraseSection = () => {
  const [email, setEmail] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      const response =  await axios.post<{message: string}>('https://api.usezeeh.com/waitlist', {
        email,
      });
      toast.success(response.data?.message );
    } catch (error) {
      if(error instanceof AxiosError) {
        const errorMessage = error.response?.data.message
        toast.error(errorMessage);
        return
      }
      toast.error(  "An error occurred");
    }
  };
  return (
    <Container>
      <TitleContainer>
        <Heading size="sm">See your credit score work for you</Heading>
        <Paragraph color="white" size="md" weight="light">
          Get your credit report now, for free.
        </Paragraph>
      </TitleContainer>
      {/* <CtaContainer onSubmit={handleSubmit}>
        <InputContainer>
          <Input
            value={email}
            type="email"
            placeholder="Enter your email address..."
            onChange={(value) => setEmail(value)}
          />
        </InputContainer>
        <Button
          rounded
          type="submit"
          disabled={!email}
        >
          Join wait-list
        </Button>
      </CtaContainer> */}
      <DownloadContainer>
        <DownloadLink href="https://apps.apple.com/us/app/usezeeh/id6458531408">
          <AppVectors.DownloadOptions.AppleStore />
        </DownloadLink>
        <DownloadLink href="https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share">
          <AppVectors.DownloadOptions.PlayStore />
        </DownloadLink>
      </DownloadContainer>
      <BackgroundImageContainer>
        <img src={appImages.transparentBackground} alt="" />
      </BackgroundImageContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.darkestGray};
  color: ${({ theme }) => theme.colors.white};
  padding: 3.5rem 1rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(24),
      theme.helpers.$rem(56)
    )};
  overflow: hidden;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  right: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  top: -15%;

  img {
    position: relative;
    display: block;
    ${({ theme }) =>
      theme.fluid.screen(
        'max-width',
        theme.helpers.$rem(400),
        theme.helpers.$rem(1224)
      )};
  }

  ${({ theme }) => theme.media.md} {
    top: -2%;

    img {
      right: -10%;

      ${({ theme }) =>
        theme.fluid.screen(
          'max-width',
          theme.helpers.$rem(500),
          theme.helpers.$rem(1224)
        )};
    }
  }
  ${({ theme }) => theme.helpers.$custom(25)} {
    display: none;
  }
`;
const TitleContainer = styled.div`
  margin-block: 2rem 1.5rem;
  ${({ theme }) =>
    theme.fluid.screen(
      'margin-top',
      theme.helpers.$rem(16),
      theme.helpers.$rem(32)
    )};
  z-index: 1;
  position: relative;
  max-width: 80%;
  margin-inline: auto;

  h1,
  p {
    text-align: center;
  }
  h1 {
    ${textGradients.textGradient3};
    margin-bottom: 1rem;
  }
`;

const CtaContainer = styled.form`
  z-index: 1;
  position: relative;
  margin-inline: auto;

  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin-inline: auto;
    font-size: 1rem;
    ${({ theme }) =>
      theme.fluid.screen(
        'max-width',
        theme.helpers.$rem(200),
        theme.helpers.$rem(282)
      )};
  }

  ${({ theme }) =>
    theme.fluid.screen(
      'margin-bottom',
      theme.helpers.$rem(60),
      theme.helpers.$rem(141)
    )};

  ${({ theme }) =>
    theme.fluid.screen(
      'max-width',
      theme.helpers.$rem(240),
      theme.helpers.$rem(400)
    )};
`;

const InputContainer = styled.div`
  flex: 1;
  width: 100%;
  ${({ theme }) => theme.media.md} {
    font-size: 14px;
  }
`;

const DownloadContainer = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  a,
  svg {
    width: fit-content;
    ${({ theme }) =>
      theme.fluid.screen(
        'height',
        theme.helpers.$rem(32),
        theme.helpers.$rem(40)
      )};
  }
`;

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
export default CatchyPhraseSection;
