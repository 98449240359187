const Lend = () => (
  <svg
    width="148"
    height="175"
    viewBox="0 0 148 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5683 173.846C14.5249 168.516 13.0642 161.167 11.3948 153.052C9.7254 144.937 8.24382 137.602 7.19001 132.275C6.66832 129.619 6.24054 127.468 5.9484 125.971C5.80232 125.233 5.68755 124.655 5.60061 124.258C5.55887 124.066 5.53105 123.91 5.5067 123.812C5.48236 123.715 5.47192 123.659 5.47192 123.659C5.47192 123.659 5.47192 123.715 5.48931 123.816C5.5067 123.917 5.53453 124.07 5.56583 124.261C5.63886 124.662 5.7432 125.243 5.87536 125.981C6.14664 127.475 6.55355 129.633 7.06828 132.296C8.09079 137.626 9.57238 144.975 11.2418 153.087C12.9112 161.199 14.3997 168.548 15.4535 173.871"
      fill="#E0E0E0"
    />
    <path
      d="M14.0553 136.77C13.9757 136.857 13.9057 136.953 13.8467 137.055C13.7215 137.247 13.5476 137.529 13.3354 137.877C12.9111 138.573 12.3546 139.555 11.7599 140.645L10.2505 143.447L9.79839 144.297C9.7349 144.396 9.68242 144.502 9.64188 144.613C9.72591 144.529 9.79736 144.433 9.85403 144.328C9.97924 144.14 10.1566 143.858 10.3618 143.506C10.7792 142.81 11.3217 141.818 11.9164 140.721L13.4467 137.936C13.631 137.605 13.7945 137.323 13.9128 137.09C13.9732 136.99 14.0211 136.882 14.0553 136.77Z"
      fill="#E0E0E0"
    />
    <path
      d="M9.1759 144.565C9.20025 144.526 7.23175 143.287 4.78678 141.779C2.34181 140.272 0.331584 139.113 0.307238 139.151C0.282893 139.189 2.24791 140.432 4.69636 141.936C7.14481 143.44 9.15156 144.62 9.1759 144.565Z"
      fill="#E0E0E0"
    />
    <path
      d="M12.2851 156.923C12.1744 156.871 12.0576 156.832 11.9373 156.809L10.9879 156.551C10.1879 156.332 9.08197 156.025 7.85774 155.642C6.63352 155.259 5.5658 154.887 4.78675 154.598L3.88597 154.274C3.77439 154.226 3.6577 154.191 3.53818 154.17C3.63897 154.237 3.74758 154.292 3.86163 154.333C4.07378 154.431 4.38331 154.563 4.76936 154.716C5.54493 155.026 6.62309 155.413 7.83688 155.806C9.05066 156.199 10.1636 156.474 10.967 156.666C11.3739 156.76 11.7008 156.829 11.9304 156.871C12.0462 156.903 12.1652 156.92 12.2851 156.923Z"
      fill="#E0E0E0"
    />
    <path
      d="M18.2567 150.831C18.1613 150.891 18.0737 150.962 17.9958 151.043C17.8359 151.19 17.6098 151.392 17.3316 151.667C16.7751 152.199 16.0169 152.941 15.1857 153.755C14.3545 154.57 13.6241 155.357 13.0711 155.903C12.8068 156.178 12.5912 156.405 12.4451 156.565C12.3632 156.641 12.2919 156.728 12.233 156.822C12.3286 156.763 12.4163 156.692 12.4938 156.61C12.6503 156.467 12.8798 156.262 13.1546 155.987C13.7111 155.458 14.4727 154.713 15.3039 153.898C16.1352 153.083 16.8794 152.314 17.415 151.761C17.6828 151.486 17.895 151.259 18.0411 151.099C18.1237 151.019 18.1962 150.929 18.2567 150.831Z"
      fill="#E0E0E0"
    />
    <path
      d="M22.385 161.38C22.2595 161.459 22.1429 161.551 22.0372 161.655L21.1851 162.448C20.4686 163.127 19.4879 164.071 18.4028 165.122C17.3177 166.173 16.3578 167.127 15.6657 167.827L14.8484 168.663C14.7434 168.76 14.648 168.867 14.5632 168.983C14.6884 168.904 14.8049 168.811 14.911 168.708L15.7665 167.911C16.4621 167.235 17.4603 166.292 18.5488 165.24C19.6374 164.189 20.5799 163.235 21.2755 162.535L22.0928 161.7C22.1998 161.602 22.2976 161.495 22.385 161.38Z"
      fill="#E0E0E0"
    />
    <path
      d="M14.7266 168.75C14.5881 168.684 14.4437 168.631 14.2953 168.593L13.1024 168.238C12.0973 167.925 10.7061 167.486 9.18629 166.96C7.66644 166.435 6.3031 165.916 5.31538 165.568L4.1468 165.105C4.00647 165.044 3.86097 164.996 3.71206 164.962C3.84226 165.043 3.97964 165.112 4.12246 165.167C4.39025 165.289 4.78326 165.456 5.27364 165.658C6.24746 166.062 7.60732 166.588 9.13412 167.113C10.6609 167.639 12.0521 168.06 13.0676 168.342C13.5789 168.485 13.9893 168.593 14.2779 168.663C14.4247 168.705 14.5748 168.734 14.7266 168.75Z"
      fill="#E0E0E0"
    />
    <path d="M146.595 33.7355H114.031V80.981H146.595V33.7355Z" fill="#E0E0E0" />
    <path d="M144.964 33.7355H112.4V80.981H144.964V33.7355Z" fill="#F5F5F5" />
    <path d="M140.919 38.8531H116.448V75.8635H140.919V38.8531Z" fill="white" />
    <path
      d="M140.916 75.8634C140.916 75.8634 140.916 75.8007 140.916 75.6824C140.916 75.564 140.916 75.3865 140.916 75.1567C140.916 74.6902 140.916 74.0113 140.916 73.1271C140.916 71.3516 140.916 68.7684 140.895 65.5029C140.895 58.9753 140.87 49.7358 140.853 38.8531L140.936 38.9331H116.466L116.553 38.8461C116.553 52.8063 116.553 65.6804 116.553 75.8564L116.483 75.7868L134.294 75.8216L139.215 75.839H140.505C140.797 75.839 140.954 75.839 140.954 75.839H140.523H139.243L134.339 75.8564L116.483 75.8947H116.41V75.8251C116.41 65.6491 116.41 52.775 116.393 38.8148V38.7242H116.483H140.954H141.034V38.8078C141.016 49.7114 141.003 58.9718 140.996 65.5098C140.996 68.7684 140.978 71.3481 140.971 73.1166C140.971 73.9939 140.971 74.6693 140.971 75.1288C140.971 75.3551 140.971 75.5257 140.971 75.6476C140.971 75.7694 140.916 75.8634 140.916 75.8634Z"
      fill="#E0E0E0"
    />
    <path
      d="M145.054 33.7355C144.888 34.034 144.693 34.3161 144.473 34.578C144.098 35.0862 143.559 35.7756 142.947 36.5171C142.335 37.2586 141.761 37.9096 141.336 38.3901C141.12 38.6561 140.88 38.902 140.62 39.1246C140.805 38.8365 141.013 38.564 141.242 38.31L142.811 36.4057L144.376 34.5014C144.58 34.2272 144.807 33.971 145.054 33.7355Z"
      fill="#E0E0E0"
    />
    <path
      d="M141.044 75.745C142.261 77.6804 143.693 79.4719 145.312 81.0854Z"
      fill="white"
    />
    <path
      d="M145.312 81.0854C145.04 80.8881 144.79 80.6616 144.567 80.41C143.999 79.8329 143.462 79.2263 142.957 78.5927C142.451 77.9591 141.978 77.3003 141.538 76.6188C141.343 76.3457 141.178 76.0528 141.044 75.745C141.263 75.9979 141.463 76.2667 141.642 76.5492C141.99 77.054 142.508 77.7433 143.096 78.4813C143.684 79.2194 144.247 79.8739 144.658 80.323C144.893 80.5614 145.112 80.8161 145.312 81.0854Z"
      fill="#E0E0E0"
    />
    <path d="M112.397 80.9427L116.661 75.6754Z" fill="white" />
    <path
      d="M116.661 75.6754C116.022 76.6078 115.333 77.5049 114.598 78.363C113.909 79.2605 113.175 80.1215 112.397 80.9427C113.037 80.0106 113.726 79.1123 114.459 78.2516C115.148 77.3557 115.883 76.4958 116.661 75.6754Z"
      fill="#E0E0E0"
    />
    <path
      d="M112.397 33.7355C113.137 34.5217 113.834 35.348 114.483 36.2107C115.173 37.0384 115.818 37.9031 116.414 38.8009C115.679 38.013 114.988 37.1856 114.344 36.3221C113.649 35.4961 112.998 34.6326 112.397 33.7355Z"
      fill="#E0E0E0"
    />
    <path
      d="M133.295 46.3658H125.101V69.6527H133.295V46.3658Z"
      fill="#E0E0E0"
    />
    <path
      d="M44.3758 26.1879V52.218H7.02303L5.68056 26.3689L44.3758 26.1879Z"
      fill="#E0E0E0"
    />
    <path
      d="M5.68056 26.3689L5.68056 52.1624H43.0333V26.3689L5.68056 26.3689Z"
      fill="white"
    />
    <path
      d="M43.2072 52.3399H5.50319V26.1984H43.2072V52.3399ZM5.85446 51.9918H42.8385V26.5465H5.85446V51.9918Z"
      fill="#E0E0E0"
    />
    <path d="M38.985 29.5753H9.72538V48.9595H38.985V29.5753Z" fill="white" />
    <path
      d="M38.9851 29.5753H38.4251H36.8183L30.7876 29.5997L9.72542 29.6415L9.79846 29.5753C9.79846 35.4936 9.79846 42.0595 9.79846 48.9561L9.70803 48.8621L38.9677 48.8829L38.8946 48.9561C38.8946 43.0551 38.9225 38.2195 38.9329 34.8496C38.9329 33.1681 38.9329 31.8521 38.9503 30.9505C38.9503 30.5048 38.9503 30.1637 38.9503 29.9269C38.9503 29.6937 38.9503 29.5788 38.9503 29.5788C38.9503 29.5788 38.9503 29.6902 38.9503 29.9269C38.9503 30.1637 38.9503 30.5014 38.9503 30.94C38.9503 31.8382 38.9503 33.1472 38.9677 34.8217C38.9677 38.2021 38.9885 43.0551 39.0059 48.9735V49.0466H38.9329H9.67325H9.57935V48.9561C9.57935 42.0595 9.57935 35.4971 9.57935 29.5753V29.4883H9.6663L30.7841 29.5301L36.794 29.5544H38.3834H38.7903L38.9851 29.5753Z"
      fill="#E0E0E0"
    />
    <path
      d="M5.67711 26.2957C5.90773 26.4083 6.12379 26.5485 6.32052 26.7134C6.70309 26.9884 7.22478 27.3818 7.78472 27.8309C8.34467 28.28 8.8281 28.7082 9.17589 29.025C9.37642 29.1856 9.55633 29.3703 9.71149 29.5751C9.67671 29.6134 8.77593 28.8788 7.65604 27.9737C6.96183 27.4565 6.30087 26.8961 5.67711 26.2957Z"
      fill="#E0E0E0"
    />
    <path
      d="M43.0298 26.3688C42.4159 26.9736 41.7592 27.5332 41.0648 28.0433C40.4056 28.6018 39.7083 29.1136 38.978 29.5751C39.5924 28.9697 40.249 28.4089 40.9431 27.8971C41.6018 27.339 42.2991 26.8283 43.0298 26.3688Z"
      fill="#E0E0E0"
    />
    <path d="M43.002 52.1693L38.8285 48.7889Z" fill="white" />
    <path
      d="M38.8355 48.7889C39.5851 49.2799 40.3008 49.8207 40.9779 50.4078C41.692 50.948 42.3683 51.5366 43.002 52.1693C42.2518 51.6794 41.536 51.1385 40.8596 50.5505C40.1465 50.009 39.4703 49.4206 38.8355 48.7889Z"
      fill="#E0E0E0"
    />
    <path
      d="M5.67711 52.1623C6.2873 51.5656 6.93807 51.0118 7.62474 50.5052C8.27368 49.9524 8.96178 49.4474 9.68366 48.9943C9.07623 49.5927 8.42649 50.1465 7.73951 50.6514C7.09051 51.2056 6.40114 51.7107 5.67711 52.1623Z"
      fill="#E0E0E0"
    />
    <path
      d="M15.6657 35.6154V42.1046H34.0777V35.6154H15.6657Z"
      fill="#E0E0E0"
    />
    <path
      d="M131.772 112.62C129.892 114.414 127.523 115.609 124.964 116.054C122.404 116.499 119.771 116.174 117.397 115.12C115.022 114.066 113.014 112.33 111.626 110.132C110.238 107.935 109.533 105.374 109.599 102.775C109.666 100.176 110.502 97.655 112.001 95.5317C113.5 93.4085 115.595 91.7784 118.021 90.8478C120.446 89.9172 123.093 89.7281 125.626 90.3043C128.158 90.8806 130.463 92.1963 132.249 94.0848C134.638 96.6073 135.93 99.9758 135.841 103.451C135.751 106.926 134.288 110.224 131.772 112.62Z"
      fill="#EBEBEB"
    />
    <path
      d="M129.626 110.353C128.194 111.721 126.389 112.633 124.439 112.973C122.489 113.314 120.482 113.067 118.672 112.266C116.862 111.464 115.331 110.142 114.271 108.469C113.212 106.795 112.673 104.844 112.721 102.863C112.77 100.883 113.405 98.961 114.545 97.3416C115.685 95.7221 117.28 94.4777 119.127 93.7659C120.975 93.0542 122.991 92.907 124.922 93.343C126.853 93.7791 128.611 94.7788 129.974 96.2155C131.8 98.1367 132.79 100.705 132.724 103.357C132.659 106.008 131.545 108.524 129.626 110.353Z"
      fill="#F5F5F5"
    />
    <path
      d="M129.852 110.134C129.807 110.092 130.096 109.758 130.548 109.114C131.176 108.212 131.66 107.217 131.981 106.165C132.196 105.444 132.333 104.702 132.388 103.951C132.443 103.096 132.387 102.237 132.221 101.396C132.139 100.94 132.023 100.49 131.873 100.052C131.554 99.1182 131.094 98.2389 130.51 97.4443C130.203 97.0304 129.868 96.6395 129.504 96.2746C129.136 95.9141 128.742 95.5802 128.325 95.2754C127.919 94.9838 127.491 94.7231 127.046 94.4956C126.615 94.2757 126.169 94.0895 125.71 93.9386C125.271 93.7888 124.821 93.6724 124.364 93.5905C123.518 93.4359 122.655 93.3915 121.797 93.4582C121.048 93.5196 120.307 93.6632 119.589 93.8864C118.542 94.2193 117.553 94.7125 116.657 95.3486C116.017 95.7976 115.69 96.0901 115.645 96.0448C115.6 95.9996 115.871 95.6479 116.473 95.1083C117.338 94.3511 118.335 93.761 119.415 93.3677C120.164 93.0927 120.943 92.9081 121.735 92.8176C122.648 92.7167 123.572 92.7401 124.479 92.8872C124.972 92.9656 125.458 93.082 125.933 93.2354C126.43 93.3902 126.914 93.5858 127.379 93.8202C127.865 94.0589 128.33 94.3359 128.771 94.6488C129.221 94.9762 129.648 95.3358 130.047 95.7245C130.439 96.1175 130.801 96.54 131.129 96.9883C131.447 97.4292 131.73 97.8949 131.974 98.3808C132.209 98.845 132.408 99.3269 132.569 99.8221C132.723 100.296 132.839 100.781 132.916 101.274C133.071 102.182 133.102 103.107 133.01 104.024C132.927 104.817 132.751 105.598 132.485 106.35C132.101 107.434 131.521 108.438 130.774 109.312C130.245 109.904 129.884 110.165 129.852 110.134Z"
      fill="#E0E0E0"
    />
    <path
      d="M119.193 108.01L117.961 109.232L116.876 108.118L118.087 106.935C117.081 105.765 116.393 104.369 116.275 103.29L118.229 102.576C118.407 103.652 118.861 104.663 119.547 105.511L121.404 103.694C120.5 102.165 119.565 100.379 121.158 98.8196C122.34 97.6673 124.187 97.5385 126.103 99.1016L127.341 97.8936L128.426 99.0111L127.213 100.195C127.949 101.04 128.473 102.048 128.743 103.136L126.83 103.92C126.622 103.098 126.254 102.326 125.748 101.646L123.836 103.485C124.754 105.006 125.665 106.768 124.096 108.299C122.938 109.427 121.109 109.56 119.193 108.01ZM123.018 102.092L124.601 100.546C123.905 100.087 123.338 100.17 122.973 100.546C122.608 100.922 122.705 101.455 123.029 102.092H123.018ZM122.204 106.618C122.594 106.238 122.49 105.709 122.204 105.065L120.667 106.566C121.328 107.018 121.857 106.966 122.215 106.618H122.204Z"
      fill="#E0E0E0"
    />
    <path
      d="M80.3547 128.202C81.2535 130.779 81.3683 133.565 80.6844 136.208C80.0006 138.85 78.549 141.23 76.5133 143.046C74.4777 144.862 71.9495 146.032 69.2488 146.409C66.5481 146.787 63.7964 146.353 61.342 145.164C58.8876 143.975 56.8408 142.083 55.4608 139.729C54.0808 137.375 53.4296 134.664 53.5896 131.939C53.7496 129.214 54.7136 126.598 56.3597 124.422C58.0058 122.246 60.2598 120.608 62.8365 119.715C66.284 118.515 70.0664 118.735 73.3517 120.327C76.637 121.919 79.1561 124.751 80.3547 128.202Z"
      fill="#EBEBEB"
    />
    <path
      d="M77.2698 129.271C77.9542 131.235 78.0413 133.357 77.5201 135.37C76.9988 137.384 75.8926 139.197 74.3415 140.58C72.7904 141.964 70.8641 142.856 68.8064 143.143C66.7487 143.43 64.6521 143.1 62.782 142.194C60.9119 141.288 59.3523 139.847 58.3007 138.053C57.2491 136.26 56.7527 134.194 56.8743 132.118C56.996 130.042 57.7302 128.049 58.984 126.39C60.2379 124.732 61.955 123.484 63.9181 122.803C66.5456 121.889 69.4279 122.057 71.9315 123.27C74.4351 124.483 76.3552 126.642 77.2698 129.271Z"
      fill="#F5F5F5"
    />
    <path
      d="M77.1481 128.961C77.089 128.986 76.8942 128.564 76.4734 127.858C75.8806 126.868 75.1393 125.976 74.2753 125.212C73.6796 124.692 73.0289 124.239 72.3347 123.861C71.5402 123.433 70.6939 123.11 69.8167 122.9C69.3435 122.784 68.8621 122.703 68.3768 122.66C67.3479 122.562 66.3101 122.62 65.2989 122.834C64.7706 122.949 64.2511 123.102 63.7442 123.29C63.2402 123.487 62.7509 123.72 62.28 123.986C61.8235 124.247 61.3885 124.544 60.9793 124.874C60.5811 125.192 60.209 125.541 59.8664 125.918C59.535 126.276 59.2305 126.658 58.9552 127.06C58.4405 127.803 58.0243 128.609 57.717 129.459C57.4527 130.203 57.2696 130.973 57.171 131.757C57.0345 132.903 57.0767 134.063 57.2962 135.196C57.4527 136.004 57.5883 136.446 57.5292 136.47C57.4701 136.495 57.2475 136.081 56.9936 135.273C56.6416 134.111 56.5095 132.894 56.6041 131.684C56.7369 129.875 57.3361 128.132 58.3431 126.625C58.6299 126.186 58.9509 125.771 59.303 125.382C59.666 124.974 60.0615 124.596 60.4854 124.251C60.9223 123.891 61.3876 123.568 61.8766 123.283C62.3812 122.99 62.908 122.738 63.4521 122.528C63.9986 122.321 64.5602 122.157 65.1319 122.037C65.6867 121.923 66.2495 121.853 66.8152 121.828C67.3618 121.801 67.9096 121.819 68.4533 121.88C68.9733 121.936 69.4884 122.032 69.994 122.166C70.9277 122.413 71.8247 122.783 72.6616 123.266C73.3837 123.691 74.0537 124.199 74.6579 124.78C75.5286 125.614 76.2363 126.604 76.7447 127.697C77.0994 128.484 77.1933 128.944 77.1481 128.961Z"
      fill="#E0E0E0"
    />
    <path
      d="M70.5088 138.25L71.1313 139.969L69.5976 140.526L68.9889 138.852C67.4378 139.311 65.8066 139.364 64.7215 139.008L64.8885 136.829C65.9941 137.121 67.1564 137.121 68.262 136.829L67.33 134.26C65.4762 134.465 63.3651 134.587 62.5687 132.39C61.9809 130.761 62.6556 128.933 64.9824 127.778L64.3633 126.072L65.8971 125.511L66.5057 127.189C67.6249 126.85 68.809 126.783 69.9593 126.994L69.8793 129.163C69.0045 129.006 68.1074 129.024 67.2395 129.215L68.1855 131.83C70.0358 131.611 72.1156 131.499 72.8981 133.665C73.4789 135.273 72.8146 137.08 70.5088 138.25ZM66.5127 132.011L65.7197 129.832C64.9754 130.309 64.8154 130.876 64.9963 131.381C65.1771 131.886 65.7579 132.032 66.5127 132.011ZM70.4949 134.758C70.3001 134.218 69.7506 134.089 69.0063 134.1L69.7749 136.22C70.4809 135.771 70.6618 135.249 70.4949 134.758Z"
      fill="#E0E0E0"
    />
    <path
      d="M28.0087 94.1336C28.043 96.424 27.3976 98.6731 26.1542 100.596C24.9109 102.519 23.1255 104.03 21.024 104.937C18.9226 105.843 16.5996 106.106 14.3492 105.69C12.0987 105.275 10.0219 104.201 8.38179 102.603C6.74163 101.006 5.61182 98.9573 5.13537 96.7168C4.65893 94.4763 4.85726 92.1447 5.70528 90.0172C6.5533 87.8896 8.01287 86.0617 9.89922 84.7649C11.7856 83.468 14.0139 82.7606 16.3021 82.7321C19.3646 82.6912 22.318 83.8692 24.5132 86.0071C26.7084 88.1451 27.9657 91.0681 28.0087 94.1336Z"
      fill="#EBEBEB"
    />
    <path
      d="M25.2577 94.1685C25.281 95.9135 24.7867 97.6262 23.8373 99.0899C22.8879 100.554 21.5262 101.703 19.9244 102.392C18.3225 103.08 16.5525 103.278 14.8383 102.96C13.1241 102.642 11.5427 101.822 10.2942 100.604C9.04571 99.3856 8.18618 97.8242 7.82435 96.117C7.46253 94.4098 7.61468 92.6336 8.26155 91.013C8.90842 89.3924 10.0209 88.0003 11.4584 87.0127C12.8958 86.0252 14.5936 85.4866 16.3369 85.4651C17.4939 85.449 18.6427 85.6615 19.7175 86.0904C20.7924 86.5193 21.7722 87.1562 22.6008 87.9646C23.4294 88.773 24.0906 89.7371 24.5465 90.8017C25.0024 91.8662 25.2441 93.0103 25.2577 94.1685Z"
      fill="#F5F5F5"
    />
    <path
      d="M25.2543 93.8934C25.1986 93.8934 25.1569 93.5104 25.0074 92.849C24.8009 91.9033 24.4489 90.9954 23.964 90.1579C23.2451 88.9199 22.2335 87.8775 21.0182 87.1221C20.6713 86.9039 20.3086 86.7119 19.9331 86.5477C19.5452 86.374 19.1439 86.232 18.7332 86.123C18.3081 86.0051 17.8743 85.9213 17.436 85.8723C16.9845 85.8228 16.53 85.8065 16.0761 85.8236C15.6229 85.8471 15.1719 85.9029 14.7267 85.9907C14.2955 86.0752 13.8712 86.1915 13.4572 86.3388C13.0562 86.4845 12.6677 86.6626 12.2956 86.8715C11.9315 87.0664 11.5827 87.2886 11.2522 87.5364C10.647 87.9909 10.1034 88.5222 9.63501 89.117C9.22591 89.6394 8.87598 90.2057 8.59164 90.8054C8.17918 91.6862 7.9058 92.6258 7.78128 93.5905C7.69086 94.2868 7.6839 94.6662 7.62826 94.6662C7.57261 94.6662 7.50653 94.2833 7.52044 93.5696C7.54651 92.557 7.76118 91.5581 8.15342 90.6244C8.42843 89.9752 8.77861 89.3606 9.19679 88.7932C9.67996 88.1411 10.2471 87.5558 10.8836 87.0525C11.2285 86.7803 11.5936 86.5348 11.9756 86.3179C12.7893 85.8538 13.6707 85.5202 14.5875 85.3292C15.0681 85.232 15.5552 85.1704 16.0448 85.1447C16.5336 85.1282 17.0229 85.1469 17.509 85.2004C17.9793 85.2581 18.4444 85.3524 18.9002 85.4824C19.3427 85.6052 19.7743 85.7648 20.1905 85.9594C20.5899 86.1405 20.9748 86.3523 21.3416 86.593C22.0208 87.0363 22.6373 87.5691 23.1745 88.177C23.6387 88.7078 24.038 89.2922 24.3639 89.9177C24.8379 90.8132 25.1399 91.7898 25.2543 92.7968C25.3412 93.5 25.2925 93.8934 25.2543 93.8934Z"
      fill="#E0E0E0"
    />
    <path
      d="M17.5089 99.5298L17.5506 101.065L16.1803 101.103L16.1386 99.6064C14.7822 99.5611 13.4676 99.1712 12.699 98.6037L13.3945 96.9083C14.1962 97.4369 15.1215 97.7477 16.0795 97.81L16.0204 95.5193C14.4866 95.192 12.7755 94.729 12.7233 92.769C12.6851 91.3138 13.7041 90.0361 15.8534 89.7332L15.8117 88.2084L17.182 88.1736L17.2237 89.6671C18.2051 89.6963 19.1657 89.9575 20.0269 90.4295L19.387 92.1354C18.7324 91.7786 18.0137 91.555 17.2724 91.4774L17.335 93.8099C18.8653 94.1267 20.5486 94.5897 20.6008 96.5219C20.6599 97.9492 19.6513 99.2095 17.5089 99.5298ZM15.9752 93.5035L15.923 91.5575C15.1996 91.7385 14.9248 92.1458 14.9353 92.6019C14.9457 93.0579 15.363 93.3295 15.9752 93.5035ZM18.4097 96.7447C18.4097 96.2643 17.9923 96.0171 17.3976 95.8256L17.4498 97.7195C18.1454 97.5663 18.434 97.1833 18.4201 96.7447H18.4097Z"
      fill="#E0E0E0"
    />
    <path
      d="M101.087 29.5253C99.1768 26.3153 98.2618 22.6109 98.4576 18.8807C98.6534 15.1505 99.9512 11.5622 102.187 8.56987C104.423 5.57753 107.496 3.31562 111.017 2.07033C114.539 0.825046 118.351 0.652353 121.971 1.57412C125.591 2.49589 128.856 4.47069 131.353 7.24863C133.85 10.0266 135.467 13.4828 135.999 17.18C136.531 20.8772 135.955 24.6492 134.343 28.0188C132.731 31.3883 130.155 34.2039 126.943 36.1093C124.813 37.3752 122.454 38.2091 120.002 38.5633C117.55 38.9175 115.052 38.7851 112.651 38.1737C110.25 37.5623 107.993 36.4838 106.009 34.9999C104.024 33.516 102.352 31.6557 101.087 29.5253Z"
      fill="#360B81"
    />
    <path
      opacity="0.4"
      d="M99.1298 30.771C97.2272 27.5583 96.3198 23.8537 96.5225 20.1255C96.7251 16.3972 98.0287 12.8127 100.268 9.82527C102.508 6.83779 105.583 4.58144 109.105 3.3415C112.627 2.10157 116.437 1.93371 120.054 2.85917C123.672 3.78463 126.933 5.76185 129.427 8.54082C131.921 11.3198 133.534 14.7757 134.064 18.4717C134.594 22.1677 134.016 25.9378 132.403 29.3053C130.79 32.6727 128.215 35.4864 125.003 37.3906C120.694 39.9402 115.548 40.6751 110.697 39.434C105.846 38.1928 101.686 35.0771 99.1298 30.771Z"
      fill="white"
    />
    <path
      d="M122.565 33.209C122.112 33.4803 121.642 33.7239 121.159 33.9386C117.873 35.3787 114.161 35.5145 110.778 34.3182C107.395 33.122 104.594 30.6835 102.944 27.4975C101.293 24.3115 100.916 20.6167 101.89 17.1631C102.864 13.7095 105.115 10.7559 108.187 8.90153C109.15 8.35242 110.178 7.92787 111.248 7.63809C114.556 6.6134 118.126 6.8412 121.277 8.27813C124.429 9.71507 126.941 12.2608 128.337 15.4305C129.733 18.6002 129.914 22.1726 128.847 25.4674C127.779 28.7622 125.537 31.5493 122.547 33.298L122.565 33.209Z"
      fill="white"
    />
    <path
      d="M108.507 21.6422C106.443 19.2221 107.226 16.3038 110.287 14.0795L109.023 11.8196L111.052 10.6273L112.316 12.8872C114.127 11.9815 116.127 11.518 118.152 11.5349L118.722 14.5777C117.03 14.5058 115.345 14.8415 113.81 15.5564L115.732 19.0086C118.045 18.4036 120.679 17.852 122.565 20.0585C124.451 22.265 123.811 25.3969 120.572 27.639L121.836 29.8989L119.807 31.0912L118.526 28.8313C117.091 29.5268 115.528 29.9205 113.935 29.9879L113.241 27.0162C114.556 27.0189 115.856 26.7334 117.049 26.1798L115.038 22.6209C112.849 23.1903 110.287 23.653 108.507 21.6422ZM117.405 21.9447L119.06 24.8986C120.038 24.0089 120.199 23.1192 119.629 22.3718C119.06 21.6244 118.366 21.8735 117.476 21.8735L117.405 21.9447ZM111.301 19.2221C111.58 19.4762 111.924 19.6475 112.295 19.7166C112.666 19.7858 113.049 19.7501 113.401 19.6136L111.835 16.8199C110.981 17.6562 110.785 18.5281 111.372 19.2221H111.301Z"
      fill="#360B81"
    />
    <path
      d="M83.0163 121.716C105.809 97.7673 86.3162 73.7628 108.998 49.7861H69.8445C47.0931 73.735 66.6281 97.7534 43.8767 121.688L83.0163 121.716Z"
      fill="#360B81"
    />
    <path
      d="M79.6746 118.486H52.8436C54.236 116.801 53.7347 115.423 52.0917 115.423C65.2914 95.665 54.7512 75.8515 67.9509 56.0937C68.9817 55.9567 69.9752 55.6167 70.8738 55.0933C71.7725 54.5699 72.5584 53.8735 73.1862 53.0444H100.017C98.6248 54.7292 99.1261 56.1077 100.783 56.1077C87.5833 75.8654 98.1236 95.6789 84.9239 115.437C83.8899 115.57 82.893 115.908 81.9915 116.432C81.0901 116.955 80.3024 117.654 79.6746 118.486Z"
      fill="#6936F5"
    />
    <path
      opacity="0.8"
      d="M75.3583 96.9459C78.3902 96.9102 81.3012 95.7516 83.5284 93.6941C85.7556 91.6366 87.1408 88.8264 87.4162 85.8069C87.7504 79.6108 83.5315 74.5704 77.5025 74.5704C74.4712 74.6033 71.5603 75.7614 69.3347 77.8197C67.1091 79.878 65.7277 82.6899 65.4585 85.7094C65.1104 91.9055 69.3293 96.9459 75.3583 96.9459ZM78.2544 90.1093C77.6992 90.1392 77.1529 89.9604 76.7228 89.6081C76.3489 89.2792 76.07 88.8561 75.9152 88.3828C75.73 87.8013 75.595 87.205 75.5115 86.6005H73.0052C73.0428 87.3997 73.2467 88.1821 73.6039 88.8979L71.7381 89.6498C71.2754 88.7007 71.0418 87.6563 71.0559 86.6005H69.4546C69.4546 86.0993 69.5243 85.598 69.5521 85.0968H71.1951C71.3132 84.106 71.7534 83.1814 72.4482 82.4652C72.7306 82.1796 73.0667 81.9527 73.4371 81.7974C73.8074 81.6421 74.2048 81.5615 74.6064 81.5602C75.1665 81.5253 75.7189 81.7045 76.1519 82.0614C76.5275 82.3972 76.8104 82.8239 76.9734 83.3006C77.1625 83.8876 77.3068 84.488 77.4051 85.0968H79.8557C79.8538 84.559 79.7741 84.0244 79.619 83.5095C79.4926 83.0172 79.2906 82.5475 79.0202 82.1171L80.886 81.2956C81.2019 81.7906 81.4282 82.3373 81.5544 82.9107C81.7253 83.6076 81.8049 84.3237 81.7911 85.0411H83.3923C83.3923 85.5423 83.3227 86.0436 83.2948 86.5448H81.6518C81.5036 87.5184 81.0671 88.4253 80.3987 89.1486C80.1289 89.4546 79.7997 89.7025 79.431 89.8772C79.0624 90.0519 78.6621 90.1499 78.2544 90.165V90.1093Z"
      fill="white"
    />
    <path
      d="M148 174.88C148 174.929 114.929 174.971 74.1432 174.971C33.3577 174.971 0.279358 174.929 0.279358 174.88C0.279358 174.832 33.3438 174.786 74.1432 174.786C114.942 174.786 148 174.828 148 174.88Z"
      fill="#263238"
    />
  </svg>
);
export default Lend;
