export const appColors = {
  // brand colors
  primary: '#6936F5',

  // other colors
  lightPurple: '#C6B5F4',
  darkPurple: '#4110A8',
  darkPurple2: '#360B81',

  // monochrome colors
  white: '#FFFFFF',
  lightGray: '#D7D7D7',
  darkGray: '#3A384B',
  darkGray2: '#242739',
  darkestGray: '#0B081C',
  black: '#000000',

  //page colors
  pageBg: '#F8F9FD',
} as const;
