const LeftChevron = () => (
  <svg
    width="16"
    height="28"
    viewBox="0 0 16 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.04401 14L15.388 24.41C15.7802 24.8056 16.0002 25.34 16.0002 25.897C16.0002 26.454 15.7802 26.9885 15.388 27.384C15.1946 27.5793 14.9644 27.7344 14.7107 27.8402C14.457 27.946 14.1849 28.0005 13.91 28.0005C13.6351 28.0005 13.363 27.946 13.1093 27.8402C12.8556 27.7344 12.6254 27.5793 12.432 27.384L0.612005 15.488C-0.203995 14.666 -0.203995 13.334 0.612005 12.512L12.432 0.616016C12.6254 0.420698 12.8556 0.265654 13.1093 0.15984C13.363 0.0540257 13.6351 -0.000453949 13.91 -0.000453949C14.1849 -0.000453949 14.457 0.0540257 14.7107 0.15984C14.9644 0.265654 15.1946 0.420698 15.388 0.616016C15.7802 1.01156 16.0002 1.54601 16.0002 2.10302C16.0002 2.66002 15.7802 3.19447 15.388 3.59002L5.04401 14Z"
      fill="#CCCBD4"
    />
  </svg>
);

export default LeftChevron;
