import styled from 'styled-components';
import { Statistic } from './data';
import { Heading, Paragraph } from '../ui';

type StatisticItemProps = {
  statistic: Statistic;
};
const StatisticItem = ({ statistic }: StatisticItemProps) => {
  const { label, value } = statistic;
  return (
    <Container>
      <Heading size="lg" weight="bold">
        {value}
      </Heading>
      <Paragraph size="md" weight="light">
        {label}
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  max-width: 11.625rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  
  h1,
  p {
    text-align: center;
  }
`;

export default StatisticItem;
