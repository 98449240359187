import { FormEventHandler, useState } from 'react';
import styled, { css } from 'styled-components';
import HeroQuotes from './quotes';
import { Button, Heading, Paragraph } from '../ui';
import { AppIcons, appImages, AppVectors } from '../../assets';
import { textGradients } from '../../theme/components/text-gradient';
import Input from '../ui/input';
import toast from 'react-hot-toast';
import axios, { AxiosError } from "axios";


const HeroSection = () => {
  const [email, setEmail] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> =async (e) => {
    e.preventDefault();
    try {
      const response =  await axios.post<{message: string}>('https://api.usezeeh.com/waitlist', {
        email
      });
      toast.success(response.data.message);


    }catch (error) {
      if(error instanceof AxiosError) {
        const errorMessage = error.response?.data.message
        toast.error(errorMessage);
        return
      }
      toast.error(  "An error occurred");
    }
  };

  return (
    <Container>
      <BodyContainer>
        <TitleContainer>
          <Heading size="xl" color="white">
            Move your finances forward
          </Heading>
          <AppVectors.LineWave />
        </TitleContainer>
        <SubtitleContainer>
          <Paragraph size="xl" color="white">
            Improve your credit score and borrow better with tips that help you
            get smarter with your money
          </Paragraph>
        </SubtitleContainer>
        {/* <CtaContainer onSubmit={handleSubmit}>
          <InputContainer>
            <Input
              value={email}
              type="email"
              placeholder="Enter your email address..."
              onChange={(value) => setEmail(value)}
            />
          </InputContainer>
            <Button
              rounded
              rightIcon={() => <AppIcons.ArrowRight />}
              type="submit"
              disabled={!email}
            >
              Join waitlist
            </Button>
        </CtaContainer> */}
        <MiscTextContainer>
          <Paragraph size="sm" color="white">
            No harm to your credit rating. Free forever.
          </Paragraph>
        </MiscTextContainer>
        <DownloadContainer>
          <DownloadLink href="https://apps.apple.com/us/app/usezeeh/id6458531408">
            <AppVectors.DownloadOptions.AppleStore />
          </DownloadLink>
          <DownloadLink href="https://play.google.com/store/apps/details?id=com.zeehtechnologies.zeeh_mobile_app&pcampaignid=web_share">
            <AppVectors.DownloadOptions.PlayStore />
          </DownloadLink>
        </DownloadContainer>
      </BodyContainer>
      <HeroQuotes />
      <MockupsContainer>
        <DarkPhoneContainer>
          <img src={appImages.heroPhoneDark} alt="Phone in dark mode" />
        </DarkPhoneContainer>
        <LightPhoneContainer>
          <img src={appImages.heroPhoneLight} alt="Phone in Light mode" />
        </LightPhoneContainer>
      </MockupsContainer>
      <BackgroundImageContainer>
        <img src={appImages.transparentBackground} alt="" />
      </BackgroundImageContainer>
    </Container>
  );
};

const Container = styled.div`
  overflow-x: hidden;
  width: 100vw;
  z-index: 1;
  position: relative;
  ${({ theme }) =>
    theme.fluid.screen(
      'min-height',
      theme.helpers.$rem(300),
      theme.helpers.$rem(960)
    )};
  background-color: ${({ theme }) => theme.colors.darkestGray};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-inline',
      theme.helpers.$rem(16),
      theme.helpers.$rem(100)
    )};
  ${({ theme }) =>
    theme.fluid.screen(
      'padding-block',
      theme.helpers.$rem(32),
      theme.helpers.$rem(16)
    )};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BackgroundImageContainer = styled.div`
  z-index: -1;

  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  img {
    display: block;
    max-height: 105%;
  }
  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

const BodyContainer = styled.div`
  ${({ theme }) =>
    theme.fluid.screen(
      'max-width',
      theme.helpers.$rem(200),
      theme.helpers.$rem(616)
    )};
  ${({ theme }) => theme.media.md} {
    margin-inline: auto;
    ${({ theme }) =>
      theme.fluid.screen(
        'max-width',
        theme.helpers.$rem(310),
        theme.helpers.$rem(616)
      )};
  }
  width: 100%;
`;
const TitleContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  h1 {
    ${textGradients.textGradient1};
  }
  svg {
    position: absolute;
    top: calc(100% - 12%);
    right: 0;
    width: 100%;
  }
  ${({ theme }) => theme.media.md} {
    h1 {
      text-align: center;
    }
  }
`;
const SubtitleContainer = styled.div`
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.md} {
    p {
      text-align: center;
    }
  }
`;

const CtaContainer = styled.form`
  min-width: 300px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    font-size: 1rem;
  }

  ${({ theme }) => theme.media.md} {
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 100%;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  width: 100%;
  ${({ theme }) => theme.media.md} {
    font-size: 14px;
  }
`;

const MockupsContainer = styled.div`
  max-width: 50%;
  width: 100%;
  flex: 1;
  aspect-ratio: 600/500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

const basePhoneImageStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  img {
    position: relative;
    display: block;
    width: 100%;
    max-width: 310px;
    ${({ theme }) =>
      theme.fluid.screen(
        'max-width',
        theme.helpers.$rem(100),
        theme.helpers.$rem(310)
      )};
    object-fit: contain;
  }
`;

const DarkPhoneContainer = styled.div`
  ${basePhoneImageStyles};
  z-index: 1;
  img {
    top: 50px;
    left: -90px;
    ${({ theme }) =>
      theme.fluid.screen(
        'left',
        theme.helpers.$rem(0),
        theme.helpers.$rem(-90)
      )};
    ${({ theme }) =>
      theme.fluid.screen('top', theme.helpers.$rem(0), theme.helpers.$rem(50))};
  }
`;
const LightPhoneContainer = styled.div`
  5${basePhoneImageStyles} img {
    top: -50px;
    ${({ theme }) =>
      theme.fluid.screen(
        'top',
        theme.helpers.$rem(0),
        theme.helpers.$rem(-50)
      )};
    ${({ theme }) =>
      theme.fluid.screen(
        'left',
        theme.helpers.$rem(0),
        theme.helpers.$rem(90)
      )};
  }
`;
const MiscTextContainer = styled.div`
  margin-bottom: 1.25rem;
  ${({ theme }) => theme.media.md} {
    text-align: center;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${({ theme }) => theme.media.md} {
    justify-content: center;
  }
  a,
  svg {
    width: fit-content;
    ${({ theme }) =>
      theme.fluid.screen(
        'height',
        theme.helpers.$rem(32),
        theme.helpers.$rem(40)
      )};
  }
`;

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
export default HeroSection;
