const Mortgage = () => (
  <svg
    width="186"
    height="131"
    viewBox="0 0 186 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M166.563 126.404H154.375V126.496H166.563V126.404Z"
      fill="#EBEBEB"
    />
    <path
      d="M122.889 127.405H119.691V127.497H122.889V127.405Z"
      fill="#EBEBEB"
    />
    <path
      d="M154.007 122.989H146.945V123.081H154.007V122.989Z"
      fill="#EBEBEB"
    />
    <path d="M36.1992 123.608H20.3053V123.7H36.1992V123.608Z" fill="#EBEBEB" />
    <path d="M41.8075 123.608H39.4781V123.7H41.8075V123.608Z" fill="#EBEBEB" />
    <path d="M83.8552 125.161H49.381V125.253H83.8552V125.161Z" fill="#EBEBEB" />
    <path
      d="M88.7312 104.07H17.6704C17.1131 104.07 16.5786 103.849 16.1846 103.455C15.7905 103.061 15.5691 102.526 15.5691 101.969V2.08288C15.574 1.52878 15.7975 0.999027 16.191 0.608931C16.5846 0.218836 17.1163 -2.12431e-05 17.6704 1.54654e-09H88.7312C89.0071 1.54654e-09 89.2804 0.0543507 89.5353 0.15995C89.7903 0.265549 90.0219 0.420329 90.217 0.615451C90.4122 0.810573 90.5669 1.04222 90.6725 1.29716C90.7781 1.55209 90.8325 1.82534 90.8325 2.10128V101.969C90.8325 102.245 90.7781 102.518 90.6725 102.773C90.5669 103.028 90.4122 103.26 90.217 103.455C90.0219 103.65 89.7903 103.805 89.5353 103.91C89.2804 104.016 89.0071 104.07 88.7312 104.07ZM17.6704 0.0736003C17.1378 0.0745731 16.6273 0.286576 16.2507 0.663179C15.8741 1.03978 15.6621 1.55028 15.6611 2.08288V101.969C15.6621 102.502 15.8741 103.012 16.2507 103.389C16.6273 103.765 17.1378 103.977 17.6704 103.978H88.7312C89.2638 103.977 89.7743 103.765 90.1509 103.389C90.5275 103.012 90.7395 102.502 90.7405 101.969V2.08288C90.7395 1.55028 90.5275 1.03978 90.1509 0.663179C89.7743 0.286576 89.2638 0.0745731 88.7312 0.0736003H17.6704Z"
      fill="#EBEBEB"
    />
    <path
      d="M168.33 104.07H97.2688C96.7115 104.07 96.1771 103.849 95.783 103.455C95.3889 103.061 95.1675 102.526 95.1675 101.969V2.08288C95.1724 1.52878 95.3959 0.999027 95.7895 0.608931C96.183 0.218836 96.7147 -2.12431e-05 97.2688 1.54654e-09H168.33C168.887 1.54654e-09 169.421 0.221384 169.815 0.615451C170.21 1.00952 170.431 1.54399 170.431 2.10128V101.969C170.431 102.526 170.21 103.061 169.815 103.455C169.421 103.849 168.887 104.07 168.33 104.07ZM97.2688 0.0736003C96.7362 0.0745731 96.2257 0.286576 95.8491 0.663179C95.4725 1.03978 95.2605 1.55028 95.2595 2.08288V101.969C95.2605 102.502 95.4725 103.012 95.8491 103.389C96.2257 103.765 96.7362 103.977 97.2688 103.978H168.33C168.862 103.977 169.373 103.765 169.749 103.389C170.126 103.012 170.338 102.502 170.339 101.969V2.08288C170.338 1.55028 170.126 1.03978 169.749 0.663179C169.373 0.286576 168.862 0.0745731 168.33 0.0736003H97.2688Z"
      fill="#EBEBEB"
    />
    <path d="M185 120.483H1V120.575H185V120.483Z" fill="#EBEBEB" />
    <path
      d="M150.351 52.6091L147.432 20.8373L104.941 24.7417L107.86 56.5134L150.351 52.6091Z"
      fill="#E6E6E6"
    />
    <path
      opacity="0.9"
      d="M145.2 23.5227L107.631 26.9749L110.098 53.8251L147.667 50.373L145.2 23.5227Z"
      fill="#FAFAFA"
    />
    <path
      d="M127.923 28.4942L128.144 30.9267L113.553 32.2699L114.289 40.2923L115.08 48.7306L122.197 48.0755L133.627 47.0267L134.153 46.9752L142.065 46.2392L141.944 44.896L139.854 45.0874C139.87 44.6094 140.045 44.1505 140.351 43.7827C140.657 43.415 141.076 43.1594 141.543 43.056L141.771 43.0045L141.293 37.8194L139.883 37.9482L138.923 27.4933L127.923 28.4942ZM115.544 48.1528L114.863 40.7707L116.497 40.6198C116.681 41.2036 117.059 41.707 117.568 42.0469C118.078 42.3868 118.687 42.5428 119.297 42.4893L119.562 42.4672L119.367 40.3549L120.928 40.215L121.608 47.5971L115.544 48.1528ZM122.135 47.5493L121.399 39.6373L118.782 39.8765L118.973 41.9704C118.495 41.9531 118.036 41.7775 117.668 41.4711C117.301 41.1647 117.045 40.7449 116.942 40.2776L116.891 40.0531L114.804 40.2445L114.127 32.752L128.192 31.4603L128.928 39.4827L130.128 39.3723L132.019 39.1994L132.884 39.1184L133.561 46.5005L122.135 47.5493ZM130.083 38.8387L129.936 37.2453C130.367 37.2612 130.783 37.4058 131.13 37.6602C131.478 37.9147 131.741 38.2675 131.887 38.6731L130.083 38.8387ZM140.796 38.3861L141.182 42.5923C140.599 42.7768 140.096 43.155 139.757 43.6642C139.417 44.1735 139.262 44.7833 139.316 45.3928L139.339 45.6578L141.447 45.4627L141.473 45.7718L134.091 46.449L133.414 39.0669L139.913 38.467L140.796 38.3861ZM138.426 28.06L139.339 37.996L133.366 38.548L132.836 38.5958L132.431 38.6326C132.253 38.0413 131.877 37.5295 131.365 37.1836C130.854 36.8376 130.238 36.6788 129.623 36.7338L129.358 36.7558L129.553 38.8976H129.41L128.674 30.8752L128.497 28.969L138.426 28.06Z"
      fill="#E0E0E0"
    />
    <path
      d="M68.3914 55.076L74.7415 23.1518L32.0509 14.6601L25.7008 46.5843L68.3914 55.076Z"
      fill="#E6E6E6"
    />
    <path
      opacity="0.9"
      d="M34.0328 17.6241L28.6663 44.6036L66.4122 52.1117L71.7788 25.1323L34.0328 17.6241Z"
      fill="#FAFAFA"
    />
    <path
      d="M53.44 24.9872L52.9542 27.4307L38.2894 24.5162L36.696 32.5754L35.0106 41.0394L42.1645 42.4635L53.6498 44.7451L54.1797 44.8519L62.1248 46.4306L62.3971 45.0616L60.2958 44.6458C60.4516 44.1837 60.7559 43.7862 61.1613 43.5152C61.5667 43.2443 62.0504 43.1152 62.537 43.148H62.7762L63.8102 37.9408L62.3971 37.6575L64.4837 27.1511L53.44 24.9872ZM35.6362 40.6309L37.1082 33.212L38.7494 33.5359C38.7602 34.1623 38.9851 34.7661 39.3869 35.2469C39.7886 35.7276 40.3429 36.0562 40.9574 36.1781L41.2224 36.2296L41.6456 34.1099L43.2133 34.4227L41.7413 41.8416L35.6362 40.6309ZM42.2602 41.9483L43.8389 33.9995L41.2114 33.488L40.7955 35.5893C40.3334 35.4336 39.9359 35.1292 39.665 34.7238C39.394 34.3184 39.2649 33.8347 39.2978 33.3482V33.109L37.2002 32.6931L38.7016 25.1602L52.8328 27.9717L51.2283 36.0346L52.4354 36.2738L54.3342 36.6418L55.2027 36.8111L53.7307 44.2299L42.2602 41.9483ZM52.5642 35.7328L52.8806 34.1283C53.2971 34.2698 53.6618 34.5325 53.928 34.8826C54.1942 35.2327 54.3497 35.6543 54.3747 36.0935L52.5642 35.7328ZM63.1736 38.3935L62.3346 42.6181C61.7088 42.6297 61.1058 42.855 60.6258 43.2567C60.1458 43.6583 59.8177 44.2122 59.696 44.8261L59.6408 45.0911L61.7605 45.5142L61.6979 45.8234L54.279 44.3514L55.751 36.9325L62.283 38.2315L63.1736 38.3935ZM63.8397 27.6L61.8562 37.5765L55.8541 36.3805L55.3242 36.2775L54.9157 36.1965C54.913 35.5646 54.6916 34.9532 54.2891 34.4661C53.8866 33.979 53.3277 33.6464 52.7077 33.5248L52.4427 33.4696L52.0158 35.6187L51.8686 35.5893L53.4731 27.5301L53.8411 25.6165L63.8397 27.6Z"
      fill="#E0E0E0"
    />
    <path
      d="M111.076 19.136H107.477C106.473 19.136 105.51 18.737 104.8 18.0269C104.089 17.3167 103.69 16.3536 103.69 15.3493V11.776C103.69 10.7717 104.089 9.80851 104.8 9.09836C105.51 8.38821 106.473 7.98926 107.477 7.98926H111.076C112.08 7.99023 113.043 8.3895 113.753 9.09944C114.463 9.80938 114.862 10.772 114.863 11.776V15.375C114.855 16.3745 114.453 17.3306 113.744 18.0349C113.034 18.7393 112.076 19.135 111.076 19.136ZM107.477 9.0675C107.123 9.06797 106.772 9.13854 106.445 9.27514C106.118 9.41174 105.821 9.61167 105.572 9.86342C105.323 10.1152 105.126 10.4138 104.992 10.742C104.859 11.0702 104.791 11.4217 104.794 11.776V15.375C104.795 16.0862 105.078 16.768 105.581 17.2709C106.084 17.7738 106.766 18.0568 107.477 18.0577H111.076C111.787 18.0568 112.469 17.7738 112.972 17.2709C113.475 16.768 113.758 16.0862 113.759 15.375V11.776C113.758 11.0648 113.475 10.383 112.972 9.88008C112.469 9.37719 111.787 9.09423 111.076 9.09326L107.477 9.0675Z"
      fill="#F0F0F0"
    />
    <path
      d="M107.779 15.8976L102.531 10.6499L104.095 9.08962L107.779 12.777L114.458 6.09778L116.018 7.6581L107.779 15.8976Z"
      fill="#E0E0E0"
    />
    <path
      d="M33.8109 67.344H31.0398C30.6543 67.344 30.2725 67.2677 29.9165 67.1195C29.5606 66.9712 29.2375 66.754 28.9659 66.4804C28.6943 66.2067 28.4795 65.882 28.334 65.5249C28.1884 65.1679 28.115 64.7855 28.1179 64.4V61.64C28.1189 60.8657 28.4272 60.1234 28.975 59.5762C29.5229 59.0291 30.2655 58.7217 31.0398 58.7217H33.8109C34.5845 58.7227 35.3262 59.0305 35.8733 59.5775C36.4204 60.1246 36.7281 60.8663 36.7291 61.64V64.4C36.732 64.7852 36.6587 65.1673 36.5134 65.5241C36.3681 65.8809 36.1537 66.2054 35.8824 66.4791C35.6112 66.7527 35.2886 66.97 34.933 67.1184C34.5775 67.2668 34.1961 67.3435 33.8109 67.344ZM31.0398 59.5829C30.4929 59.5828 29.9681 59.7991 29.58 60.1845C29.1919 60.5698 28.9719 61.093 28.968 61.64V64.4C28.969 64.9488 29.1877 65.4749 29.5761 65.8626C29.9646 66.2504 30.491 66.4681 31.0398 66.4681H33.8109C34.3594 66.4681 34.8854 66.2502 35.2733 65.8624C35.6611 65.4745 35.879 64.9485 35.879 64.4V61.64C35.879 61.3684 35.8255 61.0994 35.7216 60.8485C35.6177 60.5976 35.4653 60.3696 35.2733 60.1776C35.0812 59.9855 34.8532 59.8332 34.6023 59.7292C34.3514 59.6253 34.0825 59.5718 33.8109 59.5718L31.0398 59.5829Z"
      fill="#F0F0F0"
    />
    <path
      d="M31.2717 64.8269L27.2274 60.7862L28.4307 59.5829L31.2717 62.4238L36.4163 57.2755L37.6197 58.4789L31.2717 64.8269Z"
      fill="#E0E0E0"
    />
    <path
      d="M159.608 89.9134H156.833C156.06 89.9125 155.318 89.6047 154.771 89.0576C154.224 88.5106 153.916 87.7689 153.915 86.9952V84.2205C153.916 83.4468 154.224 82.7051 154.771 82.1581C155.318 81.611 156.06 81.3032 156.833 81.3022H159.608C160.382 81.3032 161.123 81.611 161.67 82.1581C162.217 82.7051 162.525 83.4468 162.526 84.2205V86.9952C162.525 87.7689 162.217 88.5106 161.67 89.0576C161.123 89.6047 160.382 89.9125 159.608 89.9134ZM156.833 82.1523C156.285 82.1523 155.759 82.3702 155.371 82.7581C154.983 83.1459 154.765 83.672 154.765 84.2205V86.9952C154.765 87.2668 154.819 87.5357 154.923 87.7867C155.026 88.0376 155.179 88.2656 155.371 88.4576C155.563 88.6497 155.791 88.802 156.042 88.9059C156.293 89.0099 156.562 89.0634 156.833 89.0634H159.608C159.88 89.0634 160.149 89.0099 160.399 88.9059C160.65 88.802 160.878 88.6497 161.07 88.4576C161.262 88.2656 161.415 88.0376 161.519 87.7867C161.623 87.5357 161.676 87.2668 161.676 86.9952V84.2205C161.676 83.672 161.458 83.1459 161.07 82.7581C160.683 82.3702 160.157 82.1523 159.608 82.1523H156.833Z"
      fill="#F0F0F0"
    />
    <path
      d="M157.069 87.4074L153.024 83.3667L154.228 82.1634L157.069 85.0043L162.213 79.856L163.417 81.0594L157.069 87.4074Z"
      fill="#E0E0E0"
    />
    <path
      d="M62.2238 55.5963H67.7229V56.9901H66.8237V63.2445H63.123V56.9901H62.2238V55.5963Z"
      fill="#4110A8"
    />
    <g opacity="0.2">
      <path
        d="M62.2238 55.5963H67.7229V56.9901H66.8237V63.2445H63.123V56.9901H62.2238V55.5963Z"
        fill="#6936F5"
      />
    </g>
    <path
      d="M94.7345 51.1395L114.262 70.4177V87.7161H68.0273V70.4177L94.7345 51.1395Z"
      fill="#4110A8"
    />
    <path
      d="M94.7345 51.1395L114.262 70.4177V87.7161H68.0273V70.4177L94.7345 51.1395Z"
      fill="white"
    />
    <path
      d="M73.2912 51.9252L76.2656 49H94.7344L116.496 70.4177H98.0201H73.2912V51.9252Z"
      fill="#4110A8"
    />
    <path
      opacity="0.2"
      d="M73.2912 51.9252L76.2656 49H94.7344L116.496 70.4177H98.0201H73.2912V51.9252Z"
      fill="#6936F5"
    />
    <path
      d="M98.0201 70.4177L76.2656 49L54.5042 70.4177H98.0201Z"
      fill="#4110A8"
    />
    <path
      d="M76.2656 51.1395L95.7859 70.4177V87.7161H56.7385V70.4177L76.2656 51.1395Z"
      fill="#4110A8"
    />
    <path
      opacity="0.8"
      d="M76.2656 51.1395L95.7859 70.4177V87.7161H56.7385V70.4177L76.2656 51.1395Z"
      fill="white"
    />
    <path
      d="M81.0661 61.9172H71.1953V69.2681H81.0661V61.9172Z"
      fill="#4110A8"
    />
    <path
      opacity="0.4"
      d="M81.0661 61.9172H71.1953V69.2681H81.0661V61.9172Z"
      fill="white"
    />
    <path
      d="M79.8348 62.7117H72.4265V68.4779H79.8348V62.7117Z"
      fill="#263238"
    />
    <path
      d="M70.1647 87.7162H82.1037V75.3494H70.1647V87.7162Z"
      fill="#4110A8"
    />
    <path
      opacity="0.3"
      d="M70.1647 87.7162H82.1037V75.3494H70.1647V87.7162Z"
      fill="white"
    />
    <path
      d="M80.0216 77.4534H76.3625V87.7206H80.0216V77.4534Z"
      fill="#263238"
    />
    <path
      d="M75.9059 77.4534H72.2467V87.7206H75.9059V77.4534Z"
      fill="#263238"
    />
    <path
      d="M106.812 100.212H51.1909V130.463H106.812V100.212Z"
      fill="#4110A8"
    />
    <path d="M106.812 100.212H51.1909V130.463H106.812V100.212Z" fill="white" />
    <path
      d="M119.802 100.212H92.3342V130.463H119.802V100.212Z"
      fill="#4110A8"
    />
    <path
      opacity="0.8"
      d="M119.802 100.212H92.3342V130.463H119.802V100.212Z"
      fill="white"
    />
    <path
      d="M87.1601 103.252H76.2172V111.398H87.1601V103.252Z"
      fill="#4110A8"
    />
    <path
      opacity="0.3"
      d="M87.1601 103.252H76.2172V111.398H87.1601V103.252Z"
      fill="white"
    />
    <path
      d="M85.7905 104.131H77.5868V110.519H85.7905V104.131Z"
      fill="#263238"
    />
    <path
      d="M111.419 103.252H100.476V111.398H111.419V103.252Z"
      fill="#4110A8"
    />
    <path
      opacity="0.3"
      d="M111.419 103.252H100.476V111.398H111.419V103.252Z"
      fill="white"
    />
    <path
      d="M110.049 104.131H101.845V110.519H110.049V104.131Z"
      fill="#263238"
    />
    <path
      d="M77.0403 117.004H64.0499V130.458H77.0403V117.004Z"
      fill="#4110A8"
    />
    <path
      opacity="0.3"
      d="M77.0403 117.004H64.0499V130.458H77.0403V117.004Z"
      fill="white"
    />
    <path d="M70.2961 119.29H66.3187V130.458H70.2961V119.29Z" fill="#263238" />
    <path d="M74.7715 119.29H70.7941V130.458H74.7715V119.29Z" fill="#263238" />
    <path
      d="M113.01 100.469L102.143 87.5253H55.8669L45 100.469H113.01Z"
      fill="#4110A8"
    />
    <path
      opacity="0.3"
      d="M113.01 100.469L102.143 87.5253H55.8669L45 100.469H113.01Z"
      fill="#6936F5"
    />
    <path
      d="M126 100.469L115.133 87.5253H97.0102L86.1433 100.469H126Z"
      fill="#4110A8"
    />
    <path
      opacity="0.1"
      d="M126 100.469L115.133 87.5253H97.0102L86.1433 100.469H126Z"
      fill="#6936F5"
    />
    <path
      d="M66.2357 98.2541V87.5253H87.6236L78.6174 98.2541H66.2357Z"
      fill="#4110A8"
    />
    <path
      opacity="0.8"
      d="M66.2357 98.2541V87.5253H87.6236L78.6174 98.2541H66.2357Z"
      fill="white"
    />
    <path
      d="M65.6062 98.2541H78.5966V89.9178H65.6062V98.2541Z"
      fill="#4110A8"
    />
    <path
      opacity="0.6"
      d="M65.6062 98.2541H78.5966V89.9178H65.6062V98.2541Z"
      fill="white"
    />
    <path
      d="M69.805 92.8298C69.7878 92.6291 69.8346 92.4284 69.9424 92.2397C70.0503 92.0511 70.2169 91.8785 70.4322 91.7326C70.6474 91.5867 70.9068 91.4705 71.1944 91.391C71.482 91.3116 71.7918 91.2706 72.1049 91.2706C72.418 91.2706 72.7279 91.3116 73.0155 91.391C73.3031 91.4705 73.5624 91.5867 73.7777 91.7326C73.993 91.8785 74.1596 92.0511 74.2674 92.2397C74.3753 92.4284 74.422 92.6291 74.4049 92.8298V96.0125H69.805V92.8298Z"
      fill="#263238"
    />
    <path
      d="M74.398 90.3928L76.8121 87.5253H87.6236L85.2164 90.3928H74.398Z"
      fill="#4110A8"
    />
    <path
      opacity="0.2"
      d="M74.398 90.3928L76.8121 87.5253H87.6236L85.2164 90.3928H74.398Z"
      fill="#6936F5"
    />
    <path
      d="M78.1471 87.5253L80.5612 90.3928H63.6418L66.0559 87.5253H78.1471Z"
      fill="#4110A8"
    />
    <path
      d="M67.5638 103.252H56.6208V111.398H67.5638V103.252Z"
      fill="#4110A8"
    />
    <path
      opacity="0.3"
      d="M67.5638 103.252H56.6208V111.398H67.5638V103.252Z"
      fill="white"
    />
    <path
      d="M66.1873 104.131H57.9835V110.519H66.1873V104.131Z"
      fill="#263238"
    />
    <path
      d="M185.316 130.722C185.316 130.763 143.961 130.793 92.9608 130.793C41.9611 130.793 0.600464 130.763 0.600464 130.722C0.600464 130.681 41.9448 130.651 92.9608 130.651C143.977 130.651 185.316 130.684 185.316 130.722Z"
      fill="#263238"
    />
  </svg>
);

export default Mortgage;
