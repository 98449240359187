import { appImages } from "../../assets";

export const features = [
  {
    image: appImages.phoneOne,
    baseTitle: 'Credit',
    highlightedTitle: 'Score',
    subtitle: 'Find out what is stopping you',
    list: [
      'Improve your credit score.',
      'Pay attention to the patterns in your credit usage.',
      'Track your credit score history.',
    ],
  },
  {
    image: appImages.phoneTwo,
    baseTitle: 'Customized',
    highlightedTitle: 'Experience',
    subtitle: 'Understand what actions to take next',
    list: [
      'Receive alerts about changes that affect your credit score.',
      'Receive personalized advice and suggestions.',
      'Get closer to reaching your goals.',
    ],
  },
  {
    image: appImages.phoneThree,
    baseTitle: 'Personalized',
    highlightedTitle: 'Offers',
    subtitle: 'Find out what offers you’re eligible for',
    list: [
      'Get investment offers.',
      'Buy now, pay later.',
      'Get great loan offer at low interest rates.',
    ],
  },
];

export type Feature = (typeof features)[number];
